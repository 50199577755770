import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
  useState
} from 'react';
import { User } from '../shared/types/user';

export const UserDataContext = createContext<Partial<User> | null>({});

type UserDispatch = Dispatch<SetStateAction<User | null>> | (() => unknown);
export const UserContextDispatch = createContext<UserDispatch>(() => undefined);

const UserDataProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const memoizedUser = useMemo(() => user, [user]);

  return (
    <>
      <UserDataContext.Provider value={memoizedUser}>
        <UserContextDispatch.Provider value={setUser}>
          {children}
        </UserContextDispatch.Provider>
      </UserDataContext.Provider>
    </>
  );
};

export default UserDataProvider;

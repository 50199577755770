import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CustomButton from './Controls/Button';
import { ControlElement, FormProps, GenericFormProps } from './types';
import { Container, InputRowContainer, InputRowSubContainer } from './styles';
import { Alert, Text, Stack, Illustration } from '@uala/abra';
import FormLayout from './FormLayout';
import Select from './Controls/Select';
import Input from './Controls/Input';
import Checkbox from './Controls/Checkbox';
import InputMask from './Controls/InputMask';
import PasswordHelper from './Controls/PasswordHelper';
import useQuery from '../../hooks/useQuery';
import { normalizeRegexValidation } from './utils';
import { getMask } from '../../features/Onboarding/utils';
import { useNavigate } from 'react-router-dom';
import Link from './Controls/Link';
import ButtonsContainer from './Controls/ButtonsContainer';
import InfoLayout from './InfoLayout';
import { IllustrationsLargeTypes } from '@uala/abra/dist/src/illustrations/types';

export const GenericForm: React.FC<GenericFormProps> = ({
  form,
  onSubmit = () => {},
  onRedirect = () => {},
  partialSubmit = () => {},
  loading = false,
  showLogo = false,
  firstBackButton = undefined,
  showBackButton = false,
  showStepProgress = false,
  form_variant = undefined,
  illustrationPosition = undefined,
  handleCustomValidations = () => {},
  children
}) => {
  const navigate = useNavigate();
  const currentStep = useQuery().get('currentStep');
  const activeStep = parseInt(currentStep ?? '1');
  const isMultiStepForm = Array.isArray(form);
  const changeStep = (step: number) => {
    navigate({
      search: `currentStep=${step}`
    });
  };
  const [activeForm, setActiveForm] = useState<FormProps | undefined>(
    undefined
  );
  const [tempFields, setTempFields] = useState<string[]>([]);

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    defaultValues: {
      ...JSON.parse(window.sessionStorage.getItem('formValues') || '{}'),
      termsAndConditions: false
    }
  });
  const formValues = methods.getValues();

  useEffect(() => {
    if (!methods.formState.isSubmitting) {
      window.sessionStorage.setItem('formValues', JSON.stringify(formValues));
    }
  }, [formValues]);

  useEffect(() => {
    isMultiStepForm ? setActiveForm(form[activeStep - 1]) : setActiveForm(form);
  }, [activeStep, form, isMultiStepForm]);

  const handleNext = async () => {
    if (await methods.trigger()) {
      const continueFlow = await partialSubmit(formValues, activeStep);

      if (Number.isInteger(activeStep) && continueFlow !== false)
        changeStep(activeStep + 1);
    }
  };

  const handleBack = async () => {
    tempFields.forEach((tempField) => {
      methods.resetField(tempField);
    });
    if (activeStep === 1 && firstBackButton) {
      navigate(firstBackButton);
      return;
    }
    if (activeStep) changeStep(activeStep - 1);
  };

  const handleFormSubmit = () => {
    window.sessionStorage.removeItem('formValues');
    methods.handleSubmit(onSubmit)();
  };

  const handleSubmit = (() => {
    if (!isMultiStepForm || activeStep === form.length) {
      return handleFormSubmit;
    }
    return handleNext;
  })();

  const renderInputByType = (input: ControlElement, index: number) => {
    handleCustomValidations({ input, methods: methods });
    if (input.render === false) {
      return null;
    }

    if (input.validations?.pattern) {
      normalizeRegexValidation(input.validations.pattern);
    }

    if (input.type === 'mask') {
      input.maskExp = getMask(input.name);
    }

    switch (input.type) {
      case 'string':
      case 'number':
      case 'email':
      case 'password':
        return <Input key={`${input.name}-${index}`} {...input} />;
      case 'passwordHelper':
        return <PasswordHelper key={`${input.name}-${index}`} {...input} />;
      case 'mask':
        return <InputMask key={`${input.name}-${index}`} {...input} />;
      case 'options':
        return <Select key={`${input.name}-${index}`} {...input} />;
      case 'checkbox':
        if (!tempFields.find((tempField) => tempField === input.name)) {
          tempFields.push(input.name);
          setTempFields(tempFields);
        }
        return <Checkbox key={`${input.name}-${index}`} {...input} />;
      default:
        return null;
    }
  };

  return (
    <FormLayout
      showLogo={showLogo}
      showBackButton={
        showBackButton &&
        ((isMultiStepForm && activeStep! > 1) || !!firstBackButton)
      }
      activeStep={showStepProgress ? activeStep : undefined}
      maxFormSteps={isMultiStepForm ? form.length : undefined}
      handleBack={handleBack}
      illustration={
        activeForm?.illustration && illustrationPosition === 'side'
          ? activeForm?.illustration
          : undefined
      }
      form_variant={form_variant}
    >
      {activeForm?.top_illustration && (
        <Stack justify="center" css={{ marginBottom: '20px' }}>
          <Illustration
            name={activeForm.top_illustration as IllustrationsLargeTypes}
          />
        </Stack>
      )}

      {activeForm && (
        <InfoLayout
          activeForm={activeForm}
          illustrationPosition={illustrationPosition}
        />
      )}
      {children}
      {activeForm?.inputs && methods ? (
        <>
          <FormProvider {...methods}>
            <Container
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              {activeForm?.inputs.map(
                (inputs: ControlElement[], index1: number) => {
                  return (
                    <InputRowContainer key={index1} className={'input-row'}>
                      {inputs.map((input: ControlElement, index2: number) => {
                        return (
                          <InputRowSubContainer
                            key={`${index1}-${index2}`}
                            className={input.css_classes}
                          >
                            {renderInputByType(input, index2)}
                          </InputRowSubContainer>
                        );
                      })}
                    </InputRowContainer>
                  );
                }
              )}
            </Container>

            {activeForm?.alert && (
              <Alert
                description={activeForm.alert}
                variant="information"
                css={{ marginBottom: '16px' }}
              />
            )}

            {activeForm?.button_label && (
              <ButtonsContainer form_variant={form_variant}>
                <CustomButton
                  onClick={handleSubmit}
                  disabled={!methods.formState.isValid || loading}
                >
                  {activeForm?.button_label}
                </CustomButton>
              </ButtonsContainer>
            )}

            {activeForm?.captcha_label && (
              <Stack
                justify="center"
                direction="row"
                align="center"
                css={{ marginTop: '24px' }}
              >
                <Text size="sm">{activeForm.captcha_label}</Text>
                <Text size="sm">-</Text>
                <Text size="sm">
                  <a
                    href="https://policies.google.com/privacy?hl=es-419"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacidad
                  </a>
                </Text>
                <Text size="sm">-</Text>
                <Text size="sm">
                  <a
                    href="https://policies.google.com/terms?hl=es-419"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Condiciones
                  </a>
                </Text>
              </Stack>
            )}
          </FormProvider>
        </>
      ) : (
        (activeForm?.button_label || activeForm?.redirect_button) && (
          <ButtonsContainer form_variant={form_variant}>
            <>
              {activeForm?.alert && (
                <Alert
                  description={activeForm.alert}
                  variant="information"
                  css={{ marginBottom: '6px' }}
                />
              )}

              {activeForm?.button_label && (
                <CustomButton
                  onClick={handleSubmit}
                  form_variant={form_variant}
                >
                  {activeForm?.button_label}
                </CustomButton>
              )}
              {activeForm?.redirect_button && (
                <Link
                  href={activeForm.redirect_button.route}
                  onClick={onRedirect}
                >
                  {activeForm.redirect_button.label}
                </Link>
              )}
            </>
          </ButtonsContainer>
        )
      )}
    </FormLayout>
  );
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 18px;
  margin-left: 20px;
  gap: 8px;

  &.passwordHelper {
    margin: 20px;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Icon = styled.img`
  width: 24px;
`;

export const Text = styled.span`
  margin-left: 10px;
`;

import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Title = styled.h1`
  width: 100%;
  margin: 0px 0px 12px 0px;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #3a3a3a;
  text-align: center;
`;

export const Subtitle = styled.h2`
  width: 100%;
  margin: 0px 0px 30px 0px;
  text-align: center;
  align-self: flex-start;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #3a3a3a;
  white-space: break-spaces;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  
  b {
    font-weight: 700;
  }
`

export const IconAddon = styled.img`
  position: absolute;
  font-size: 0.875rem;
  color: #3564fd;
  right: 0;
  top: 22px;
  border: none;
  background: transparent;
`;

export const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 8px 0 8px 258px;
  object-fit: contain;
`;

export const InputRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 18px;
  width: 100%;

  &:has(.input-text),
  :has(.input-select) {
    margin-bottom: 35px;
  }

  &:has(.input-password) {
    margin-bottom: 20px;
  }

  &:has(.password-helper) {
    margin-bottom: 20px;
  }

  &:has(.tyc) {
    margin-top: auto;
  }
`;

export const InputRowSubContainer = styled.div`
  width: 100%;

  &.input-select > div > span {
    display: block;
    height: 0;
    bottom: -1px;
  }

  &.input-text > div > div,
  &.input-password > div > div {
    margin-bottom: 1px;

    & ~ span {
      display: block;
      height: 0;
    }
  }
`;

export const IllustrationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 35px auto;

  @media (max-width: 1200px) {
    align-items: center;
    margin: 0;
    & img {
      height: 180px;
    }
  }
`;

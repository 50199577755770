import { ButtonContainer, SubmitButton } from './styles';

export const CustomButton: React.FC<{
  disabled?: boolean;
  onClick?: Function;
  form_variant?: string;
  children: string;
}> = ({
  disabled = false,
  onClick = () => {},
  form_variant = undefined,
  children
}) => {
  return (
    <ButtonContainer className={form_variant}>
      <SubmitButton
        onClick={() => {
          if (!disabled) {
            onClick();
          }
        }}
        disabled={disabled}
      >
        {children}
      </SubmitButton>
    </ButtonContainer>
  );
};

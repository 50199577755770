import styled from 'styled-components';
import {
  color,
  display,
  maxWidth,
  minWidth,
  space,
  width
} from 'styled-system';
import { StyledSelectProps } from './types';

export const StyledSelectBase = styled.select``;

export const StyledSelect = styled.div<StyledSelectProps>`
  position: relative;
  width: 100%;
  min-height: 50px;

  & button {
    position: absolute;
    font-size: 0.875rem;
    color: #3564fd;
    right: 0;
    top: 22px;
    border: none;
    background: transparent;
  }

  & label {
    display: block;
    position: absolute;
    font-size: 1rem;
    cursor: text;
    top: 20px;
    color: #565656;
    z-index: -10;
    line-height: 1.33;
    transition: 0.2s;

    &.has-value {
      top: 0;
      font-size: 0.75rem;
      color: ${(props) => (props.error ? '#d34040' : '#565656')};
    }
  }

  span {
    -ms-flex-item-align: start;
    align-self: flex-start;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: normal;
    color: #d34040;
    margin-top: 3px;
    display: block;
    position: absolute;
    bottom: -4px;
  }

  & ${StyledSelectBase} {
    position: absolute;
    bottom: 0;
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 1px solid ${(props) => (props.error ? '#ff8382' : '#bdbdbd')};
    outline: 0;
    font-size: 1rem;
    color: #3a3a3a;
    line-height: 1.3rem;
    padding: 5px 25px 5px 0;
    background: transparent;
    transition: border-color 0.2s;

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(${'images/arrow-down.svg'});
    background-repeat: no-repeat, repeat;
    background-position: right 0 bottom 25%, 0 0;

    &:required,
    &:invalid {
      box-shadow: none;
    }

    &:focus {
      border-color: #3564fd;

      & ~ label {
        position: absolute;
        top: 0;
        display: block;
        transition: 0.2s;
        font-size: 0.75rem;
        color: #3564fd;
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px white inset !important;
    }
  }

  ${space}
  ${color}
  ${display}
  ${width}
  ${maxWidth}
  ${minWidth}
`;

export const StyledOutlinedSelect = styled(StyledSelect)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',

  [StyledSelectBase]: {
    border: '1px solid #bdbdbd',
    borderRadius: '4px',
    height: '34px',

    '&:focus': {
      borderColor: theme.active,

      '& ~ label': {
        position: 'static',
        fontSize: '1rem',
        transition: 'none'
      }
    }
  },

  '& label': {
    position: 'static',
    marginBottom: '8px'
  }
}));

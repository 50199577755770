import styled from 'styled-components';

export const Container = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  gap: 10px;
  margin-bottom: 40px;

  &.information-page {
    position: absolute;
    top: 74px;
    width: auto;
  }

  @media (max-width: 1200px) {
    height: initial;
    flex-direction: row;

    &.simple {
      margin-bottom: 20px;
    }
  }

`;

export const SubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.feedback-page {
    justify-content: center;
  }
  &.simple {
    justify-content: center;
  }

  @media (max-width: 1200px) {
    justify-content: end;
  }
`;

export const UalaBisLogo = styled.img`
  height: 28px;
`;

export const StepperHelper = styled.div`
  width: 101px;
  height: 28px;
  background-color: #e7eeff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & span {
    color: #3564fd;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
`;

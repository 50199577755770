import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericForm from '../../components/GenericForm';
import useDesktopSize, { useGetDevice } from '../../hooks/useDesktopSize';
import data from './data/returnOnboardingForm.json';
import { createAmplitudeEvent } from '../../contexts/AmplitudeProvider';

const ReturnOnboarding: React.FC = () => {
  const navigate = useNavigate();
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  const device = useGetDevice()

  const onSubmit = () => {
    createAmplitudeEvent('registro_cobros_mail_registrado_registro_incompleto', { device, retoma_registro: 'retoma' })
    navigate('/validate-email?source=ro');
  };

  const onRedirect = () => {
    createAmplitudeEvent('registro_cobros_mail_registrado_registro_incompleto', { device, retoma_registro: 'otro_mail' })
  };

  return (
    <GenericForm
      form={data}
      onSubmit={onSubmit}
      onRedirect={onRedirect}
      loading={false}
      showLogo={isDesktop}
      illustrationPosition={'form'}
      form_variant={'feedback-page'}
    />
  );
};

export default ReturnOnboarding;

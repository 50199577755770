import { Checkbox } from '@uala/ui';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import { ControlElement } from '../../types';
import { CheckboxContainer } from './styles';

export const CustomCheckbox: React.FC<ControlElement> = ({
  name,
  placeholder,
  validations,
  defaultValue = false
}) => {
  let ref = useRef(null);
  const { register } = useFormContext();
  const [checked, setChecked] = useState(Boolean(defaultValue));

  const handleOnClick = () => {
    setChecked(!checked);
  };

  return (
    <CheckboxContainer ref={ref}>
      <Checkbox
        aria-label={placeholder}
        {...register(name, validations)}
        checked={checked}
        onClick={handleOnClick}
      />
      <ReactMarkdown children={placeholder} />
    </CheckboxContainer>
  );
};

import styled from 'styled-components';
import { Box } from '@uala/ui';

type StyledWrapperProps = {
  hasIllustration: boolean
}

export const Wrapper = styled.div<StyledWrapperProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: ${(props) => (props.hasIllustration ? '60%' : '100%')};
  justify-content: center;
`;

export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: start;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 250px;
  max-width: 60%;

  &.feedback-page {
    max-width: 100%;
  }
  &.simple {
    max-width: 100%;
    margin: 0 42px;
    @media (max-width: 1200px) {
      margin: 0 24px;
    }
  }

  @media (max-width: 1200px) {
    max-width: 100%;
    margin-top: 0px;
    margin: 0px 30px auto;
  }
`;

export const FormSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 250px;
  max-width: 546px;
  margin-top: 74px;
  margin-bottom: 60px;

  &.information-page {
    margin-top: unset;
    justify-content: center;
    @media (max-width: 1200px) {
      align-items: center;
      justify-content: start;
      margin-top: 20px;
    }
  }

  &.simple {
    margin-bottom: 0;
    max-width: 100%;
    margin-top: 50px;

    @media (max-width: 1200px) {
      margin: 24px 0;
    }
  }

  @media (max-width: 1200px) {
    max-width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

export const SideIllustrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: #f0f4ff;
  max-width: 40%;
  position: fixed;
  right: 0;
  width: 100%;
  height: 100%;
`;

import { LinkContainer, Link } from './styles';

export const CustomLink: React.FC<{
  href: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  children: string;
  style?: React.CSSProperties;
}> = ({
  href,
  onClick,
  children,
  style
}) => {
    return (
      <LinkContainer style={style}>
        <Link
          onClick={onClick}
          to={href}
        >
          {children}
        </Link>
      </LinkContainer>
    );
  };

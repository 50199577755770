import { ERROR_CODES } from './errorsCodes';

type ErrorResponse = {
  code: number
}

export const getErrorMessageByType = (error: ErrorResponse | undefined) => {
  let message: string = 'Algo salió mal, por favor intente de nuevo.';

  if (error && error.code && ERROR_CODES[error.code]) {
    message = ERROR_CODES[error.code];
  }

  return message;
};

import React from 'react'
import useDesktopSize from '../../hooks/useDesktopSize';
import FormLayout from '../../components/GenericForm/FormLayout';
import { useNavigate } from 'react-router-dom';
import { Text, Title } from '@uala/abra';

const TyC = () => {
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  const navigate = useNavigate()
  const handleBack = async () => {
    navigate(-1)
  };
  return (
    <FormLayout
      showLogo={isDesktop}
      showBackButton={!isDesktop}
      handleBack={handleBack}
      form_variant='simple'
    >
      <>
        <Title as='h1' color='primary' align='center' css={{ textDecorationLine: 'underline' }}>Términos y condiciones de uso de Ualá Bis México</Title>

        <p className="c14"><span className="c5">Los presentes t&eacute;rminos y condiciones y sus anexos (en adelante, el &ldquo;Contrato&rdquo; o &ldquo;T&eacute;rminos y Condiciones&rdquo; o &ldquo;T&eacute;rminos&rdquo;) constituyen un contrato entre usted (en adelante, el &ldquo;Usuario&rdquo;) y Alau Tecnolog&iacute;a MX, S.A. de C.V. (en adelante, &ldquo;Ual&aacute;&rdquo; y, junto con el Usuario, las &ldquo;Partes&rdquo;). Excepto en caso de que resulten expresamente aplicables otros t&eacute;rminos y condiciones especiales, los T&eacute;rminos y Condiciones regir&aacute;n los derechos y obligaciones de las Partes respecto de cualquier producto y/o servicio ofrecido (en adelante e indistintamente, los &ldquo;Servicios&rdquo; o &ldquo;Servicio Uala Bis&rdquo;). El Usuario se compromete a leer, comprender y aceptar todas las condiciones establecidas en este Contrato, previo al momento de realizar la solicitud de su registro como Usuario de la App o en la P&aacute;gina Web. En caso de no encontrarse de acuerdo con los T&eacute;rminos y Condiciones, por favor, abst&eacute;ngase de utilizar los Servicios.</span></p>
        <p className="c124"><span className="c5">Los presentes T&eacute;rminos y Condiciones se considerar&aacute;n aceptados desde el momento en el que el registro sea aprobado por Ual&aacute; a trav&eacute;s de la App, de acuerdo con las pol&iacute;ticas de alta del Usuarios y constituyen un acuerdo contractualmente v&aacute;lido y vinculante que regulan las condiciones para el uso de la P&aacute;gina Web, de la App, as&iacute; como los Servicios, mismos que permanecer&aacute;n vigentes mientras contin&uacute;e utilizando la P&aacute;gina Web y/o App, as&iacute; como los Servicios, o bien, hasta que se actualice una causal de terminaci&oacute;n de conformidad con lo dispuesto en los presentes T&eacute;rminos y Condiciones.</span></p>
        <Title size='sm' css={{ textDecorationLine: 'underline' }}>DEFINICIONES</Title>

        <p className="c107"><Text as='span' css={{ textDecorationLine: 'underline' }}>Adquirente</Text><span className="c5">: participante en Redes de Disposici&oacute;n facultado para la operaci&oacute;n de procesamiento, compensaci&oacute;n y liquidaci&oacute;n de pagos electr&oacute;nicos con Tarjetas de terceros.</span></p>

        <p className="c11"><Text as='span' css={{ textDecorationLine: 'underline' }}>Agregador</Text><span className="c5">: participante en Redes de Disposici&oacute;n que opera al amparo de un contrato de prestaci&oacute;n de servicios con un Adquiriente, y realiza la operaci&oacute;n de procesamiento, compensaci&oacute;n y liquidaci&oacute;n de pagos electr&oacute;nicos de Tarjetas con terceros.</span></p>

        <p className="c16"><Text as='span' css={{ textDecorationLine: 'underline' }}>App o Aplicaci&oacute;n</Text><span className="c5">: es la aplicaci&oacute;n m&oacute;vil de Ual&aacute; que se descarga desde Google Play y que permite usar el Servicio Ual&aacute; Bis (conforme se define en estos T&eacute;rminos y Condiciones).</span></p>

        <p className="c61"> <Text as='span' css={{ textDecorationLine: 'underline' }}>C&aacute;mara de Compensaci&oacute;n</Text><span className="c5">: </span><span className="c28">Significa la entidad autorizada por el Banco de M&eacute;xico de conformidad con las disposiciones de car&aacute;cter general que &eacute;ste emite al efecto para fungir como entidad central o para ser la operadora de un mecanismo de procesamiento centralizado, a trav&eacute;s del cual se realizan las acciones que correspondan para intercambiar entre Adquirentes y Emisores solicitudes de autorizaci&oacute;n, autorizaciones de pagos, rechazos de pago, devoluciones, ajustes u otras obligaciones financieras relacionadas con pagos con tarjetas, incluida la compensaci&oacute;n.</span></p>

        <p className="c61"><Text as='span' css={{ textDecorationLine: 'underline' }}>Clave</Text><span className="c5">: clave alfanum&eacute;rica que el Usuario genera para registrarse, acceder a su Cuenta de Usuario y utilizar el Sistema Ual&aacute; Bis.</span></p>

        <p className="c7"><Text as='span' css={{ textDecorationLine: 'underline' }}>Cliente y/o Comprador</Text><span className="c5">: es el tercero titular de la tarjeta de cr&eacute;dito, d&eacute;bito y/o prepago.</span></p>

        <p className="c14"><Text as='span' css={{ textDecorationLine: 'underline' }}>Contracargo</Text><span className="c5">: Se presenta cuando el Cliente y/o Comprador contacta al banco Emisor de su Medio de Pago para levantar una aclaraci&oacute;n por este concepto, quien en caso de ser procedente abona a dicho Cliente y/o Comprador el monto de la transacci&oacute;n. El banco Emisor del Medio de Pago del Cliente y/o Comprador, es quien crea una solicitud de contracargo hac&iacute;a el Usuario que proces&oacute; la transacci&oacute;n correspondiente.</span></p>

        <p className="c47"><Text as='span' css={{ textDecorationLine: 'underline' }}>Costo</Text><span className="c5">: es el pago a favor del Agregador o Ual&aacute; por los servicios brindados al amparo del presente contrato, incluyendo sus respectivos impuestos.</span></p>
        <p className="c16"><Text as='span' css={{ textDecorationLine: 'underline' }}>Cuenta Bancaria</Text><span className="c5">: es la cuenta que el Usuario se&ntilde;ala y que este aperturada en una instituci&oacute;n de cr&eacute;dito; la cual se encuentra habilitada para recibir los dep&oacute;sitos derivados de las transacciones procesadas y recibidas por Ual&aacute; en nombre y por cuenta del Usuario como parte de los Servicios Ual&aacute; Bis..</span></p>
        <p className="c7"><Text as='span' css={{ textDecorationLine: 'underline' }}>Cuenta de Usuario</Text><span className="c5">: es la cuenta Ual&aacute; Bis de titularidad del Usuario.</span></p>
        <p className="c19"><Text as='span' css={{ textDecorationLine: 'underline' }}>Emisor</Text><span className="c28">: Significa la entidad participante en la Red de Disposici&oacute;n que expide tarjetas y que, a trav&eacute;s de la C&aacute;mara de Compensaci&oacute;n de pagos con tarjeta, recibe las solicitudes de autorizaci&oacute;n de pago que le dirige el Adquirente y genera las respectivas autorizaciones de pago, rechazos de pagos, devoluciones y ajustes, con el objeto de ser enviados al Cliente y/o Comprador a trav&eacute;s de la C&aacute;mara de Compensaci&oacute;n, al Adquirente, y al Agregador que corresponda.</span></p>

        <p className="c16"><Text as='span' css={{ textDecorationLine: 'underline' }}>Link de Pago</Text><span className="c5">: software que permite a los usuarios compartir un link a una p&aacute;gina web para realizar cobros no presenciales a trav&eacute;s de los Medios de Pago habilitados por Ual&aacute;.</span></p>
        <p className="c16"><Text as='span' css={{ textDecorationLine: 'underline' }}>Medios de Pago</Text><span className="c5">: tarjetas de d&eacute;bito, cr&eacute;dito, prepagas de las marcas Visa, Mastercard y cualquier otro medio que Ual&aacute; habilite a futuro.</span></p>

        <p className="c53"><Text as='span' css={{ textDecorationLine: 'underline' }}>mPos</Text><span className="c5">: es el lector de tarjetas que al conectarlo de forma inal&aacute;mbrica a un dispositivo de telefon&iacute;a m&oacute;vil o electr&oacute;nico te permitir&aacute; cursar las operaciones de pago de terceros mediante la lectura de la banda magn&eacute;tica, chip y/o contactless de los Medios de Pago habilitados por Ual&aacute; en la modalidad presencial.</span></p>

        <p className="c7"><Text as='span' css={{ textDecorationLine: 'underline' }}>P&aacute;gina Web</Text><span className="c129">: </span><span className="c123"><a className="c18" href="https://www.google.com/url?q=http://www.ualabis.com.mx/&amp;sa=D&amp;source=editors&amp;ust=1691676535742833&amp;usg=AOvVaw1Ngwr40vROSBokDDi5_F8-">www.ualabis.com.mx</a></span></p>

        <p className="c19"><Text as='span' css={{ textDecorationLine: 'underline' }}>Red(es) de Disposici&oacute;n</Text><span className="c5">: a la Red de Medios de Disposici&oacute;n relacionada con el uso de tarjetas como Medios de Pago.</span></p>

        <p className="c16"><Text as='span' css={{ textDecorationLine: 'underline' }}>Servicio Ual&aacute; Bis</Text><span className="c5">: servicio de gesti&oacute;n y procesamiento electr&oacute;nico de pagos ofrecido por Ual&aacute;, que permite al Usuario recibir pagos por la venta de bienes y servicios ofrecidos por &eacute;ste a terceros, en forma presencial o remota, con los Medios de Pago habilitados.</span></p>

        <p className="c14"><Text as='span' css={{ textDecorationLine: 'underline' }}>Usuario</Text><span className="c5">: es la persona f&iacute;sica, mayor de edad, con plena capacidad jur&iacute;dica, que descarga la App ingresa a la P&aacute;gina Web y completa el registro y una vez aprobado utiliza el Sistema Ual&aacute; Bis para realizar operaciones de cobro y/o pagos mediante el Medio de Pago habilitados por Ual&aacute; como Agregador, para los productos y/o servicios comercializados a terceros.</span></p>
        <p className="c17"><span className="c48 c33"></span></p>
        <p className="c17"><span className="c48 c33"></span></p>
        <p className="c36"><span className="c125 c33 c130"></span></p>
        <p className="c16"><span className="c5">Para cobros presenciales ser&aacute; imprescindible el uso conjunto de la App/P&aacute;gina Web y el mPos. Para el caso de cobros efectuados a trav&eacute;s del sitio web instrumentadas de forma remota mediante el Link de Pago, no ser&aacute; necesaria la utilizaci&oacute;n del mPos.</span></p>
        <p className="c36"><span className="c26"></span></p>


        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 1. ALTA DEL SERVICIO.</Title>
        <p className="c108"><Text as='span' weight='bold'>Registro</Text><span className="c5">: Todo Usuario que desee adherirse al sistema deber&aacute; haber completado el registro satisfactoriamente y ser aprobado por Ual&aacute; de acuerdo a las pol&iacute;ticas de alta de Usuarios.</span></p>
        <p className="c80"><Text as='span' weight='bold'>Compromiso del Usuario</Text><span className="c5">: Los Usuarios que utilicen los Servicios de Ual&aacute; Bis garantizan la veracidad, exactitud, vigencia y autenticidad de la informaci&oacute;n y documentaci&oacute;n presentada.</span></p>
        <p className="c165"><span className="c5">El Usuario se compromete a mantener sus datos y la informaci&oacute;n relativa a su condici&oacute;n fiscal debidamente actualizados, informando a Ual&aacute; sobre cualquier modificaci&oacute;n a trav&eacute;s de la actualizaci&oacute;n de la informaci&oacute;n correspondiente en su Cuenta de Usuario, o poni&eacute;ndose en contacto a trav&eacute;s de la siguiente casilla de correo: </span><span className="c125 c21 c90 c129"><a className="c18" href="mailto:hola@ualabis.com.mx">hola@ualabis.com.mx</a></span></p>
        <p className="c14"><Text as='span' weight='bold'>Claves</Text><span className="c5">: El Usuario deber&aacute; haber generado durante el proceso de registro una Clave, siendo el &uacute;nico y exclusivo responsable de la confidencialidad de la misma, as&iacute; como tambi&eacute;n de todas las operaciones y/o actividades llevadas a cabo con su Clave y su Cuenta de Usuario.</span></p>
        <p className="c73"><Text as='span' weight='bold'>Infraestructura necesaria</Text><span className="c5">: el Usuario deber&aacute; contar con un dispositivo m&oacute;vil o electr&oacute;nico compatible con la P&aacute;gina Web y la App, con sistema operativo Android, acceso a Internet y/o servicio de transferencia de datos para poder descargarla.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 2. UTILIZACI&Oacute;N DEL SERVICIO UAL&Aacute; BIS.</Title>
        <p className="c109"><span className="c5">2.1 Para el servicio de gesti&oacute;n y procesamiento electr&oacute;nico de pagos instrumentado de forma remota, el Usuario podr&aacute; generar un Link de Pago que deber&aacute; compartir con su Cliente a trav&eacute;s de cualquier medio habilitado para este fin.</span></p>
        <p className="c154"><span className="c5">2.1 Para el servicio de gesti&oacute;n y procesamiento electr&oacute;nico de pagos de forma presencial ofrecido por Ual&aacute;, el Usuario podr&aacute; adquirir para operar un mPos a trav&eacute;s de la Aplicaci&oacute;n o cualquier otro canal de venta habilitado por Ual&aacute;.</span></p>
        <p className="c83"><span className="c5">Para utilizar el mPos, el dispositivo m&oacute;vil del Usuario deber&aacute; contar con tecnolog&iacute;a de conexi&oacute;n Bluetooth&reg; 2.1.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 3. OPERATIVIDAD DEL SERVICIO.</Title>
        <p className="c69"><span className="c5">Al utilizar el Servicio Ual&aacute; Bis, el Usuario otorga de forma irrevocable a Ual&aacute; un mandato para:</span></p>
        <ol type="i">
          <li className="c97 c20"><span className="c5">Cobrar, recibir, transferir, retener y/o disponer de pagos por su cuenta y orden, recaudando los fondos recibidos de los Medios de Pago habilitados para este servicio en la Cuenta Bancaria, </span><span className="c28">con el prop&oacute;sito de realizar la liquidaci&oacute;n del importe de las compras realizadas en su negocio</span><span className="c5">; y</span></li>
          <li className="c20 c37"><span className="c5">Acreditar dichos cobros en la Cuenta Bancaria que para dichos fines se&ntilde;ale el Usuario, conforme lo establecido en la CLÁUSULA 5.</span></li>
        </ol>
        <p className="c144"><span className="c5">Ual&aacute; se reserva el derecho de eliminar o agregar Medios de Pago en cualquier momento y sin previo aviso al Usuario, en el entendido que Ual&aacute; s&oacute;lo procesar&aacute; las tarjetas que reciban autorizaci&oacute;n de las Redes de Disposiciones y el Emisor.</span></p>
        <p className="c50"><span className="c5">Ual&aacute; no ser&aacute; responsable del retraso en los tiempos de dep&oacute;sito que sea ocasionado por las instituciones financieras. Ual&aacute; se reserva el derecho de cambiar la frecuencia de pagos en cualquier momento. As&iacute; como a restringir el horario para la autorizaci&oacute;n de transacciones con Medios de Pago.</span></p>
        <p className="c45"><span className="c5">Ual&aacute; no ser&aacute; responsable si por caso fortuito o fuerza mayor, se presentan fallas en sus sistemas y/o equipos o por cualquier otra causa no pudiese el Usuario hacer uso del Servicio Ual&aacute; Bis.</span></p>
        <p className="c50"><span className="c5">Ual&aacute; se reserva el derecho a rechazar cualquier transacci&oacute;n con cargo a Medios de Pago que no re&uacute;nan las caracter&iacute;sticas se&ntilde;aladas en los presente T&eacute;rminos, as&iacute; como los dep&oacute;sitos que considere fraudulentos.</span></p>
        <p className="c83"><span className="c5">El Servicio Ual&aacute; Bis &uacute;nicamente podr&aacute; ser prestado en territorio nacional (Estados Unidos Mexicanos), por lo que el Usuario tiene prohibido operar fuera de &eacute;ste.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 4. COSTO DE LOS SERVICIOS.</Title>
        <p className="c40"><span className="c5">El Usuario deber&aacute; pagar a Ual&aacute; el Costo que surja de las comisiones, cuotas y/o cobros determinados por Ual&aacute; dependiendo del Medio de Pago utilizado por el Comprador al momento de realizar la operaci&oacute;n. Las comisiones, cuotas o cobros pueden ser consultadas en: </span><span className="c5"><a className="c18" href="https://www.google.com/url?q=http://www.ualabis.com.mx/&amp;sa=D&amp;source=editors&amp;ust=1691676535745894&amp;usg=AOvVaw3DcQ0AVYvJZno0l7jf2pu7">www.ualabis.com.mx</a></span></p>
        <p className="c86"><span className="c5">Ual&aacute; podr&aacute; cobrar otras comisiones por el mantenimiento y/o uso del Servicio Ual&aacute; Bis, previa notificaci&oacute;n al Usuario dentro del plazo de 10 (diez) d&iacute;as naturales, entendi&eacute;ndose expresamente facultado para efectuar los correspondientes d&eacute;bitos en la Cuenta Bancaria del Usuario ligada al Servicio Ual&aacute; Bis. Las comisiones antes mencionadas no incluyen los costos que aplican las empresas de telefon&iacute;a celular por los servicios de transferencia de datos.</span></p>
        <p className="c45"><span className="c5">A todas las comisiones, cuotas y/o cobros en favor de Ual&aacute;, se les deber&aacute; adicionar el Impuesto al Valor Agregado (&ldquo;IVA&rdquo;).</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 5. ACREDITACI&Oacute;N DE FONDOS.</Title>
        <p> <span className="c5">5.1 Los fondos obtenidos por cada venta realizada por el Usuario ser&aacute;n acreditados el mismo d&iacute;a de la operaci&oacute;n en la Cuenta Bancaria ligada al Servicio Ual&aacute; Bis de acuerdo a la opci&oacute;n que haya seleccionado para cobrar y al Medio de Pago utilizado por su Cliente previa validaci&oacute;n de la operaci&oacute;n respectiva por parte de Ual&aacute;. La veracidad y exactitud de los datos bancarios queda a entera responsabilidad del Usuario, por lo que, Ual&aacute; no asume la responsabilidad si por dicha omisi&oacute;n o inexactitud no se acreditan los fondos.</span></p>
        <p className="c11"><span className="c5">El monto neto a acreditar en la Cuenta de Bancaria ligada al Servicio Ual&aacute; Bis ser&aacute; el monto de cada operaci&oacute;n menos los respectivos descuentos por comisiones, impuestos, costo financiero total (cuando se hubiera elegido la opci&oacute;n de plazos) vigente al momento de la transacci&oacute;n y otros cargos que resulten aplicables.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 6. COMPROBANTES DE LA TRANSACCI&Oacute;N.</Title>
        <p className="c97"><span className="c5">El Usuario y el Cliente recibir&aacute;n autom&aacute;ticamente un comprobante digital de la operaci&oacute;n que contendr&aacute;: el nombre del Comprador, la fecha y hora de la transacci&oacute;n, los &uacute;ltimos cuatro d&iacute;gitos de la tarjeta con la que realiz&oacute; el pago, nombre o denominaci&oacute;n social del negocio Usuario, n&uacute;mero de autorizaci&oacute;n, n&uacute;mero de transacci&oacute;n, importe, n&uacute;mero de afiliaci&oacute;n, Emisor, seg&uacute;n resulte aplicable.</span></p>
        <p className="c47"><span className="c5">El Usuario podr&aacute; en cualquier momento requerir a su Cliente el correo electr&oacute;nico a fin de remitirle una copia del comprobante digital de la operaci&oacute;n de cobro realizada.</span></p>
        <p className="c198"><span className="c5">El Usuario se compromete a conservar una copia del comprobante de cada operaci&oacute;n por el plazo de 12 (doce) meses en caso de operaciones al contado y 24 (veinticuatro) meses en caso de operaciones sujetas a plazos o mensualidades. Ual&aacute; podr&aacute; requerir en cualquier momento informaci&oacute;n y/o documentaci&oacute;n que soporte las transacciones realizadas por el Usuario y &eacute;ste se obliga a entregarla dentro de los 2 (dos) d&iacute;as naturales siguientes a Ual&aacute;. Por lo que, ser&aacute; responsabilidad del Usuario resolver cualquier reclamaci&oacute;n o controversia que derive del extrav&iacute;o, robo, p&eacute;rdida, deterioro, mutilaci&oacute;n o destrucci&oacute;n parcial o total de tales documentos, as&iacute; como del pago de da&ntilde;os y perjuicios causados por tales motivos y/o el pago de los gastos y costos por procedimientos de reivindicaci&oacute;n, oposici&oacute;n, cancelaci&oacute;n, pago, reposici&oacute;n o restituci&oacute;n a que refiere la Ley de T&iacute;tulos y Operaciones de Cr&eacute;dito, lo anterior con independencia de las obligaciones y responsabilidades del Usuario se&ntilde;aladas en el los presentes T&eacute;rminos con respecto a los referidos documentos.</span></p>
        <p className="c112"><span className="c5">El Usuario tendr&aacute; un plazo de hasta 20 (veinte) d&iacute;as naturales, para efectuar cualquier aclaraci&oacute;n y/o reclamaci&oacute;n con respecto a los dep&oacute;sitos derivados de transacciones efectuadas a trav&eacute;s de Medios de Pago, transcurrido dicho plazo se entender&aacute; que est&aacute; de acuerdo con los dep&oacute;sitos efectuados. El plazo antes mencionado se contar&aacute; a partir del dep&oacute;sito derivado de cada transacci&oacute;n efectuada a trav&eacute;s de Medios de Pago. Para atender cualquier aclaraci&oacute;n y/o reclamaci&oacute;n del Usuario, Ual&aacute; solicitar&aacute; al Cliente documentaci&oacute;n</span></p>

        <p className="c117"><span className="c5">que ampare la transacci&oacute;n correspondiente y &eacute;ste deber&aacute; proporcionarla dentro de los 2 (dos) d&iacute;as h&aacute;biles siguientes a partir de la solicitud, conforme a los siguientes criterios:</span></p>
        <ol type='a'>
          <li>
            <span>Transacciones a trav&eacute;s de mPos: tira totalizadora de transacciones generada por el mPos; cierre de lote (env&iacute;o del comprobante al correo electr&oacute;nico o celular del Cliente)); pagar&eacute;s de las transacciones respectivas; carta petici&oacute;n del Usuario;</span>
            <br />
            <Text as='span' weight='bold' css={{ textDecorationLine: 'underline' }}>Tratándose de inconformidades de Clientes será requerida la siguiente documentación</Text>
            <span>, en transacciones a trav&eacute;s de mPos: pagar&eacute; de la transacci&oacute;n.</span>
          </li>
        </ol >
        <p className="c207"><span className="c5">En caso de que el Usuario no proporcione los documentos, dentro del plazo antes referido, se har&aacute; acreedor a una pena convencional equivalente al importe total de los pagar&eacute;s que amparen las transacciones solicitadas por Ual&aacute; y que no hayan sido entregados oportunamente por el Usuario.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 7. GIROS PROHIBIDOS.</Title>
        <p className="c31"><span className="c5">El Usuario no podr&aacute; operar giros prohibidos conforme a la Lista de Exclusi&oacute;n y Manual para uso de Giros del Adquirente y siempre deber&aacute; operar dentro de la licitud. Ual&aacute; se reserva el derecho de revisar y/o modificar la Lista de Exclusi&oacute;n bajo su exclusivo y &uacute;nico criterio, bastando para ello una notificaci&oacute;n por escrito dirigida al Usuario (en el entendido que podr&aacute; realizarse la notificaci&oacute;n por correo electr&oacute;nico) de cualquier modificaci&oacute;n que se lleve a cabo con al menos 10 (diez) d&iacute;as naturales de anticipaci&oacute;n. En el entendido que, la omisi&oacute;n y/o incumplimiento del Usuario en la operaci&oacute;n de un giro y/o actividad de las no permitidas en los t&eacute;rminos del Manual de uso para Giros, ser&aacute; causal de rescisi&oacute;n.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 8. CONTRACARGOS.</Title>
        <p className="c97"><span className="c5">En aquellos casos en que el Comprador solicite un Contracargo, anulaci&oacute;n, desconocimiento o reversi&oacute;n de una operaci&oacute;n de cobro realizada por el Usuario a trav&eacute;s del Servicio Ual&aacute; Bis, Ual&aacute; trasladar&aacute; al Usuario la responsabilidad por la transacci&oacute;n cuestionada, contando el Comprador con un plazo de 90 (noventa) d&iacute;as naturales a partir de que se presente dicha operaci&oacute;n; en cuyo caso el Usuario tendr&aacute; un plazo m&aacute;ximo de 10 (diez) d&iacute;as naturales, a partir de que se presente el contracargo, para entregar al Emisor del Medio de Pago y Ual&aacute; el pagar&eacute; que ampare la operaci&oacute;n en cuesti&oacute;n. En virtud de lo anterior, ser&aacute; el Emisor del Medio de Pago del Comprador, quien crear&aacute; una solicitud de contracargo hac&iacute;a el Usuario que proces&oacute; la transacci&oacute;n correspondiente.</span></p>
        <p className="c54"><span className="c5">El Usuario autoriza de forma expresa a Ual&aacute; a descontar el monto correspondiente de ventas futuras que realice, es decir, en caso de recibir Contracargos, el Usuario autoriza a Ual&aacute; a descontar el monto que corresponda a dichos Contracargos sobre dep&oacute;sitos futuros, a fin de cubrir dichos conceptos, cuando as&iacute; resulten procedentes.</span></p>
        <p className="c61"><span className="c5">Ual&aacute; solicitar&aacute; al Usuario toda la documentaci&oacute;n que estime necesaria para validar la operaci&oacute;n con el Emisor de la tarjeta con la cual el Comprador efectu&oacute; el pago, en el entendido que la negativa del Usuario a proporcionar la informaci&oacute;n solicitada, ser&aacute; causal de rescisi&oacute;n. Conforme a la documentaci&oacute;n se&ntilde;alada en la CLÁUSULA 6 de los presentes T&eacute;rminos.</span></p>
        <p className="c14"><span className="c5">El Usuario reconoce y acepta que no recibir&aacute; devoluciones de las percepciones y retenciones oportunamente cobradas e ingresadas a la autoridad fiscal correspondientes.</span></p>
        <p className="c16"><span className="c5">El Usuario se compromete a defender y sacar en paz y a salvo a Ual&aacute; por todo reclamo y/o da&ntilde;o que pueda sufrir como consecuencia directa o indirecta del incumplimiento del Usuario de las obligaciones previstas en la presente CLÁUSULA.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 9. DEVOLUCIONES.</Title>
        <p className="c65"><span className="c5">El Usuario podr&aacute; realizar una devoluci&oacute;n a su </span><span className="c28">Cliente de los fondos recibidos por una operaci&oacute;n de cobro. Para ello, el Usuario deber&aacute; enviar un correo electr&oacute;nico a </span><span className="c125 c21 c129 c132"><a className="c18" href="mailto:hola@ualabis.com.mx">hola@ualabis.com.mx</a></span><span className="c28"><a className="c18" href="mailto:hola@ualabis.com.mx">,</a></span><span className="c28">&nbsp;acompa&ntilde;ado del comprobante de la venta a devolver; una vez recibida la solicitud de devoluci&oacute;n, el &aacute;rea de atenci&oacute;n al cliente de Ual&aacute; le enviar&aacute; al Usuario las instrucciones para que realice la transferencia SPEI por el monto que previamente se deposit&oacute; a la cuenta bancaria del Usuario por concepto de la venta a devolver. Realizada la transferencia SPEI, el Usuario deber&aacute; enviar el comprobante de la misma al correo electr&oacute;nico antes se&ntilde;alado, para que posteriormente Ual&aacute; y previa confirmaci&oacute;n de la recepci&oacute;n de fondos, se encargue de realizar la devoluci&oacute;n directamente al Cliente.</span></p>
        <p className="c93"><span className="c28">Queda expresamente se&ntilde;alado que, para que un Cliente pueda solicitar una devoluci&oacute;n al Usuario, los datos que deber&aacute; proporcionar son los siguientes: (i) n&uacute;mero de autorizaci&oacute;n de la transacci&oacute;n; (ii) fecha de la transacci&oacute;n; e (iii) importe.</span></p>
        <p className="c45"><span className="c5">El Usuario reconoce y acepta que no recibir&aacute; devoluciones de las percepciones y retenciones oportunamente cobradas e ingresadas a la autoridad fiscal correspondiente.</span></p>
        <p className="c39"><span className="c5">En todo caso, Ual&aacute; deber&aacute; revisar y aprobar las pol&iacute;ticas de entrega y devoluci&oacute;n de productos y servicios de los Usuarios, las cuales deber&aacute;n ser justas y seguras para los Clientes.</span></p>
        <p className="c22"><span className="c5">Si el Usuario no realiza la devoluci&oacute;n conforme al proceso se&ntilde;alado en el primer p&aacute;rrafo de la presente CLÁUSULA, dar&aacute; derecho a Ual&aacute; a suspender la prestaci&oacute;n del Servicio Ual&aacute; Bis al Usuario, sin responsabilidad alguna.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 10. RESPONSABILIDADES, OBLIGACIONES Y/O PROHIBICIONES DEL USUARIO.</Title>
        <ol className="c1 lst-kix_list_13-1 start">
          <li className="c14 c20"><span className="c5">El Usuario acepta y se obliga de manera expresa a utilizar el Servicio Ual&aacute; Bis &uacute;nicamente con el fin de recibir pagos efectuados con los Medios de Pago habilitados por la venta de bienes y/o prestaci&oacute;n de servicios comercializados y/u ofrecidos por &eacute;l. El Usuario asume la obligaci&oacute;n de no utilizar el Servicio Ual&aacute; Bis para otros fines fuera de los previstos en estos T&eacute;rminos y Condiciones.</span></li>
          <li className="c61 c20"><span className="c5">El Usuario acepta expresamente y se obliga a cumplir con todas las leyes y regulaci&oacute;n aplicable en relaci&oacute;n con las operaciones de cobro y cualquier relaci&oacute;n entre el Usuario y su Cliente que d&eacute; lugar a la operaci&oacute;n de cobro.</span></li>

          <li className="c14 c20"><span className="c5">El Usuario acepta y asume que la conexi&oacute;n a Internet y/o datos m&oacute;viles necesaria para el funcionamiento del mPos es a su exclusivo cargo. Asimismo, es responsabilidad del Usuario verificar antes de adherirse al Servicio que su dispositivo sea compatible con la App y con la P&aacute;gina Web, en su caso, con el dispositivo mPos.</span></li>
          <li className="c45 c20"><span className="c5">El Usuario ser&aacute; responsable, en cualquier caso, por la veracidad, autenticidad, exactitud, integridad, vigencia y actualizaci&oacute;n tanto de sus datos personales como de la informaci&oacute;n referida a su situaci&oacute;n fiscal.</span></li>
          <li className="c50 c20"><span className="c5">El Usuario ser&aacute; el &uacute;nico y exclusivo responsable de pagar cualquier tipo de impuesto que se derive de las transacciones de venta de bienes o de la presentaci&oacute;n de servicios cobrados mediante el Servicio Ual&aacute; Bis, as&iacute; como el cumplimiento de sus obligaciones fiscales que se desprendan de la realizaci&oacute;n de dichas actividades econ&oacute;micas.</span></li>
          <li className="c162 li-bullet-1"><span className="c5">El Usuario se obliga expresamente a:</span>
            <ol type='i'>
              <li className="c153 li-bullet-2"><span className="c5">Mantener los Costos que tengan en vigencia para operaciones con otros Medios de Pago.</span></li>
              <li className="c31 c20"><span className="c5">No establecer montos m&iacute;nimos y/o recargos y/o diferenciaciones de cualquier tipo para operaciones realizadas con alguno de los servicios del sistema; </span><span className="c28">as&iacute; como aplicar precios superiores o comisiones adicionales por el uso de un Medio de Pago en relaci&oacute;n con una operaci&oacute;n de pago.</span></li>
              <li className="c20 c41"><span className="c5">No discriminar entre las diferentes marcas de tarjetas, en cualquiera de sus formas y por cualquier motivo, sin perjuicio de los descuentos, promociones y/o plazos que pudieran existir.</span></li>
              <li className="c20 c44"><span className="c5">Mostrar con igual nivel de exposici&oacute;n las diferentes marcas de tarjetas aceptadas en cualquiera de los medios de difusi&oacute;n utilizados por el.</span></li>
              <li className="c9"><span className="c5">Que su sitio web: a. tenga un procedimiento claro y justo de reembolso y/o devoluci&oacute;n para los Clientes; b. coincidan con la actividad y contenido; c. descripci&oacute;n clara y justa de los bienes y servicios ofrecidos; d. proporcione un adecuado servicio al cliente (m&iacute;nimo correo electr&oacute;nico y tel&eacute;fono de contacto); se&ntilde;ale claramente divisa de pago; e. no contenga ligas que direcciones a otros sitios web, ya sea de mala reputaci&oacute;n y/o se&ntilde;alados en el Manual para uso de Giros del Adquirente.</span></li>
              <li className="c61 c20"><span className="c5">Cuando as&iacute; resulte aplicable, utilizar proveedores y/o m&eacute;todos de entrega de productos y servicios justos para los Clientes, as&iacute; como a realizar dichas entregas en tiempos aceptables.</span></li>
              <li className="c53 c20"><span className="c5">Guardar evidencia de las revisiones de URL o su sitio web (impresiones de pantalla), en su caso.</span></li>
              <li className="c16 c20"><span className="c28">No aceptar vales de despensa, seg&uacute;n lo dispuesto por el SAT, para los siguientes prop&oacute;sitos: (i) comprar de bebidas alcoh&oacute;licas o productos del tabaco; (ii) retirar el importe de su saldo en efectivo. En caso de incurrir en cualquiera de estas faltas, el Usuario ser&aacute; acreedor a las sanciones vigentes.</span></li>
              <li className="c20 c72"><span className="c28">Exhibir en su negocio y/o sucursales el material complementario del dispositivo mPos en un lugar visible.</span></li>
              <li className="c16 c20"><span className="c28">Cumplir los reglamentos y material de capacitaci&oacute;n y/o mejores pr&aacute;cticas relacionadas con los medios electr&oacute;nicos de pagos, que Ual&aacute; le proporcione. Lo cual incluye, una evaluaci&oacute;n de exposici&oacute;n al riesgo por lo menos una vez al a&ntilde;o en materia de cumplimiento y prevenci&oacute;n de fraudes, cuando se ubique en alguno de los supuestos de alto riesgo o cuando as&iacute; lo solicite Ual&aacute;.</span></li>
              <li className="c73 c20"><span className="c28">No prestar, transferir o permitir el uso de su afiliaci&oacute;n a otro negocio para el procesamiento de las transacciones.</span></li>
              <li className="c20 c61"><span className="c28">No vender productos y/o prestar servicios distintos al giro con el cual se registraron en la Aplicaci&oacute;n.</span></li>
              <li className="c122 li-bullet-3"><span className="c28">Se&ntilde;alar las </span><span className="c5">restricciones de exportaci&oacute;n.</span></li>
              <li className="c61 c20"><span className="c5">Contar con avisos legales y pol&iacute;ticas de privacidad en su p&aacute;gina de Internet. As&iacute; mismo, deber&aacute; tener una pol&iacute;tica de cancelaci&oacute;n, en la que detalle la forma de poder cancelar, plazos para reembolsar el dinero o parcialidades hacia el Cliente, por lo que, en caso de que el Usuario no genere la nota de devoluci&oacute;n o cancelaci&oacute;n o no cubra el importe, pagar&aacute; como pena convencional a Ual&aacute; el importe de la cancelaci&oacute;n o devoluci&oacute;n respectiva.</span></li>
              <li className="c47 c20"><span className="c5">Recabar la firma digital del Cliente a trav&eacute;s de la App al momento de la transacci&oacute;n, en caso de no hacerlo, el Usuario acepta hacer frente a los Contracargos que pudiesen derivar de dicha transacci&oacute;n.</span></li>
              <li className="c20 c148"><span className="c5">Responder del pago de las multas que le sean impuestas a Ual&aacute; por las marcas de los Medios de Pago o por cualquier autoridad, en virtud de las transacciones que sean procesadas por el Usuario, siempre que dichas multas deriven de incumplimientos y/o omisiones imputables al Usuario.</span></li>
              <li className="c148 c20"><span className="c5">Realizar las actividades y/o operaciones asociadas al giro de su negocio con fondos de origen l&iacute;cito.</span></li>
              <li className="c20 c30"><span className="c5">Utilizar por lo menos una vez al a&ntilde;o el material de capacitaci&oacute;n que Ual&aacute; pondr&aacute; a su disposici&oacute;n a trav&eacute;s de la p&aacute;gina web</span><span className="c84 c33">&nbsp;</span><span className="c84 c21"><a className="c18" href="https://www.google.com/url?q=http://www.ualabis.com.mx/&amp;sa=D&amp;source=editors&amp;ust=1691676535753003&amp;usg=AOvVaw24GdshK92Zc4dpviR1Q6ww">www.ualabis.com.mx</a></span><span className="c5">.</span></li>
            </ol>
          </li>
          <li className="c19 c20"><span className="c5">
            El Usuario se obliga expresamente a seguir los est&aacute;ndares de seguridad establecidos en la norma PCI_DSS. En particular se obliga a cumplir con las siguientes medidas de seguridad, entre las cuales se incluyen de forma meramente enunciativa m&aacute;s no limitativa:</span>
            <ol type='i'>
              <li className="c164 li-bullet-4"><span className="c5">Proteger los datos almacenados de los titulares de tarjetas.</span></li>
              <li className="c19 c20"><span className="c5">No almacenar, usar, copiar o transmitir y/o permitir que terceros directa o indirectamente almacenen, usen, copien o transmitan informaci&oacute;n personal del Comprador y cualquier dato de sus tarjetas.</span></li>
              <li className="c9"><span className="c5">Verificar la identidad del Comprador mediante exhibici&oacute;n de documentos oficiales de identificaci&oacute;n vigentes y validar que los datos del comprobante digital emitido coincidan con los del Comprador.</span></li>
              <li className="c47 c20"><span className="c5">Verificar que la tarjeta del Comprador no se encuentre vencida y que no tenga ning&uacute;n indicio de haber sido modificada y/o alterada.</span></li>
              <li className="c16 c20"><span className="c5">Actuar con la debida diligencia para prevenir los desconocimientos y/o cualquier otro tipo de uso indebido del Medio de Pago de su Cliente.</span></li>
              <li className="c172 li-bullet-5"><span className="c5">Mantener vigente la certificaci&oacute;n PCI, ya sea directamente o a trav&eacute;s de un tercero.</span></li>
            </ol>
          </li>
          <li className="c16 c20">
            <span className="c5">El Usuario es el &uacute;nico y exclusivo responsable por el uso del Servicio Ual&aacute; Bis y por todas las transacciones por &eacute;l cursadas a trav&eacute;s de &eacute;ste. En general, el Usuario se declara responsable y asume todas las consecuencias que se desprendan del uso del Servicio, incluyendo de forma meramente enunciativa m&aacute;s no limitativa:</span>
            <ol type='i'>

              <li className="c20 c73"><span className="c5">Transacciones efectuadas de manera fraudulenta y/o con tarjetas robadas, adulteradas y/o p&eacute;rdidas;</span></li>
              <li className="c20 c22"><span className="c5">Transacciones cuyos comprobantes no contengan informaci&oacute;n ver&iacute;dica y/o toda aquella exigida por las normas o refleje informaci&oacute;n que no coincida con la informaci&oacute;n de la operaci&oacute;n efectivamente autorizada;</span></li>
              <li className="c139 c20"><span className="c5">Transacciones que correspondan a consumos desconocidos, impugnados y/o rechazados por el Cliente.</span></li>
              <li className="c20 c39"><span className="c5">Cualquier otra transacci&oacute;n realizada en infracci&oacute;n a estos T&eacute;rminos y Condiciones o la regulaci&oacute;n vigente y aplicable en los Estados Unidos Mexicanos.</span></li>
              <li className="c20 c66"><span className="c28">Realizar transacciones con Medios de Pago a su nombre con el prop&oacute;sito de autofinanciamiento, ya sea de manera directa o mediante terceros.</span></li>
              <li className="c39 c20"><span className="c28">Indebido almacenamiento y resguardo de los comprobantes de las transacciones efectuadas.</span></li>
              <li className="c20 c139"><span className="c28">En caso de transacciones declinadas por el Emisor, no deber&aacute; completar dichas transacciones. En caso contrario, asume la responsabilidad de los contracargos derivados de dicha transacci&oacute;n.</span></li>
            </ol>
          </li>
          <li className="c154 c20"><span className="c5">
            El Usuario, sus dependientes, empleados o cualesquier tercero encargado de las ventas del Usuario, tienen prohibido, en la operaci&oacute;n de las transacciones con Medios de Pago, incurrir en los siguientes supuestos:</span>
            <ol type='a'>
              <li className="c100 li-bullet-4"><span className="c5">Aceptar pagos de Clientes para amortizar pagar&eacute;s suscritos por ellos.</span></li>
              <li className="c67 li-bullet-6"><span className="c5">Tramitar dep&oacute;sitos en su Cuenta derivados de operaciones y/o pagar&eacute;s de otros comercios.</span></li>
              <li className="c20 c71"><span className="c5">Tomar n&uacute;meros de cualquier Medio de Pago para hacer uso indebido de estos, por s&iacute; mismo o por terceros.</span></li>
              <li className="c209 li-bullet-4"><span className="c5">Desembolsar efectivo en transacciones con Medios de Pago sin haber sido autorizados.</span></li>
              <li className="c71 c20"><span className="c5">Prestar a terceros y/o se utilicen por &eacute;stos mPos asignadas al Usuario, o no generar en dicho equipo los pagar&eacute;s derivados de transacciones con Medios de Pago.</span></li>
              <li className="c20 c113"><span className="c5">Guardar, copiar, imprimir o almacenar en cualquier medio la banda magn&eacute;tica o chip de cualquier Medio de Pago y/o los datos contenidos en la misma.</span></li>
              <li className="c20 c210"><span className="c5">Efectuar dep&oacute;sitos de pagar&eacute;s fuera de la entidad en donde se encuentre el establecimiento afiliado.</span></li>
              <li className="c20 c195"><span className="c5">Efectuar transacciones con Medios de Pago en operaciones no coincidentes con el giro o actividad principal del Usuario.</span></li>
              <li className="c103 c20"><span className="c5">Para el caso de operaciones de cargos peri&oacute;dicos, fotocopiar o solicitar al tarjetahabiente por ambos lados el Medio de Pago y su identificaci&oacute;n oficial.</span></li>
            </ol>
          </li>
        </ol>
        <p className="c146"><span className="c5">El Usuario deber&aacute; resarcir los da&ntilde;os y/o perjuicios que su pudieran causar a Ual&aacute;, as&iacute; como a los titulares de los Medios de Pagos, a los Emisores y a las empresas generadoras de marcas de tarjeta en caso de que se incurra en cualquiera de los supuestos anteriores, independientemente de cualquier otra responsabilidad que le resulte al Usuario, sus dependientes, empleados o cualesquier tercero por incurrir en tales transacciones. Ual&aacute; en el caso de identificar una operaci&oacute;n no coincidente con el giro o actividad principal del Usuario, a su juicio podr&aacute; terminar la relaci&oacute;n comercial con el Usuario, sin responsabilidad, ni necesidad de requisito, aviso o tr&aacute;mite previo alguno. Siendo el Usuario responsable de las obligaciones pendientes al momento de dicha terminaci&oacute;n.</span></p>
        <p className="c17"><span className="c48 c33"></span></p>
        <p className="c25"><span className="c33 c105"></span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 11. OBLIGACIONES RESPECTO DEL USO Y CONSERVACI&Oacute;N DEL MPOS.</Title>
        <ol>
          <li>
            <span className="c5">Respecto de la adquisici&oacute;n y utilizaci&oacute;n del mPos el Usuario reconoce y se compromete a cumplir con las siguientes obligaciones:</span>
            <ol type='i'>
              <li className="c20 c95"><span className="c5">Pagar el precio que establezca Ual&aacute; para la adquisici&oacute;n del mPos y conservar la factura de compra.</span></li>
              <li className="c20 c103"><span className="c5">Adquirir el mPos exclusivamente en los lugares autorizados a tal fin y mantener el mismo dentro del territorio de los Estados Unidos Mexicanos.</span></li>
              <li className="c20 c70"><span className="c5">Utilizar el mPos exclusivamente con la App y &uacute;nicamente para los fines establecidos en los presentes T&eacute;rminos y Condiciones.</span></li>
              <li className="c66 c20"><span className="c5">Leer y seguir las instrucciones de uso y utilizar el MPos con la mayor diligencia posible conserv&aacute;ndolo con aptitud para el uso.</span></li>
              <li className="c104 li-bullet-7"><span className="c5">Utilizar personalmente el mPos.</span></li>
              <li className="c31 c20"><span className="c5">No utilizar el mPos si el mismo presenta cualquier tipo de signo de alteraci&oacute;n, disfunci&oacute;n o manipulaci&oacute;n. En ning&uacute;n caso podr&aacute; el Usuario intentar reparar o de ninguna otra manera manipular, modificar y/o adulterar el dispositivo.</span></li>
              <li className="c163 li-bullet-8"><span className="c5">Asumir la responsabilidad por hurto, robo o extrav&iacute;o del dispositivo.</span></li>
              <li className="c20 c79"><span className="c5">No vender, gravar, ceder, prestar, transferir ni alquilar el mPos a terceros, salvo casos autorizados.</span></li>
              <li className="c39 c20"><span className="c5">Abstenerse de realizar cualquier acci&oacute;n que interfiera con la seguridad o correcta funcionalidad del mPos.</span></li>
            </ol>
          </li>

          <li className="c16 c20">
            <span className="c5">Los dispositivos mPos cuentan con una garant&iacute;a de 12 (doce) meses desde la fecha de compra frente a cualquier defecto de fabricaci&oacute;n. Para reclamar la garant&iacute;a, el Usuario deber&aacute; conservar la factura de compra y efectuar el reclamo a:</span><span className="c125 c33 c90 c129">&nbsp;</span><span className="c125 c21 c90 c129"><a className="c18" href="mailto:hola@ualabis.com.mx">hola@ualabis.com.mx</a></span><span className="c5"><a className="c18" href="mailto:hola@ualabis.com.mx">.</a></span>
            <p className="c97"><span className="c5">El Usuario podr&aacute; cancelar la compra del mPos si dentro del plazo de 10 (diez) d&iacute;as naturales contados desde la fecha en que recibi&oacute; el dispositivo, efect&uacute;a la solicitud y presenta la factura de compra a: </span><span className="c125 c21 c90 c129"><a className="c18" href="mailto:hola@ualabis.com.mx">hola@ualabis.com.mx</a></span><span className="c5"><a className="c18" href="mailto:hola@ualabis.com.mx">.</a></span><span className="c5">&nbsp;En los casos de cancelaci&oacute;n, el Usuario asume por cuenta propia los gastos de env&iacute;o y/o cualquier otro gasto que pudiese corresponder.</span></p>
            <p className="c54"><span className="c5">En cualquiera de las situaciones, si el mPos ha sido da&ntilde;ado, manipulado y/o no se encuentra en id&eacute;nticas condiciones a las que se encontraba al haber sido entregado inicialmente, Ual&aacute; se reserva el derecho a no efectuar el reemplazo o sustituci&oacute;n del mPos y/o la restituci&oacute;n del importe que haya pagado el Usuario para adquirir el dispositivo.</span></p>
          </li>
        </ol>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 12. FACTURACI&Oacute;N.</Title>
        <p className="c65"><span className="c5">Ual&aacute; ser&aacute; el responsable de emitir los Comprobantes Fiscales Digitales por Internet (&ldquo;CFDI&rdquo;) por las comisiones, cuotas y cobros en favor de Ual&aacute;. Para tales efectos y de conformidad con los art&iacute;culos 29 y 29-A del C&oacute;digo Fiscal de la Federaci&oacute;n (CFF) y 39 del Reglamento del CFF, el Usuario acepta que la operaci&oacute;n tiene lugar desde el primer d&iacute;a de cada mes, hasta el &uacute;ltimo d&iacute;a del mismo mes, por lo que dichos CFDIs ser&aacute;n puestos a disposici&oacute;n del Usuario a m&aacute;s tardar 24 (veinticuatro) horas posteriores al cierre de cada mes, seg&uacute;n corresponda.</span></p>
        <p className="c166"><span className="c5">Para la emisi&oacute;n de los CFDI a que se refiere el p&aacute;rrafo anterior, el Usuario deber&aacute; compartir mediante correo electr&oacute;nico: i) Clave RFC, ii) Nombre completo o raz&oacute;n social, iii) R&eacute;gimen Fiscal y iv) Domicilio fiscal; todos de acuerdo con la Constancia de situaci&oacute;n fiscal vigente.</span></p>

        <p className="c19"><span className="c5">En caso de no contar con los datos antes se&ntilde;alados, los CFDIs ser&aacute;n emitidos con las claves gen&eacute;ricas que pone a disposici&oacute;n el Servicio de Administraci&oacute;n Tributaria a trav&eacute;s de la Resoluci&oacute;n Miscel&aacute;nea Fiscal y sus anexos vigentes.</span></p>
        <p className="c87"><span className="c5">En caso de que, el Usuario desee corregir y/o actualizar los datos de facturaci&oacute;n, deber&aacute; enviar la solicitud mediante correo electr&oacute;nico a la direcci&oacute;n </span><span className="c125 c21 c129 c183"><a className="c18" href="mailto:hola@ualabis.com.mx">hola@ualabis.com.mx</a></span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 13. RESPONSABILIDAD DE UALÁ.</Title>
        <p className="c14"><span className="c5">Ual&aacute; se reserva el derecho de suspender y/o interrumpir el Servicio Ual&aacute; Bis a su exclusivo criterio.</span></p>
        <p className="c16"><span className="c5">Ual&aacute; se reserva el derecho a establecer l&iacute;mites operativos, suspender operaciones y/o rechazar transacciones a su exclusivo criterio.</span></p>
        <p className="c126"><span className="c5">Ual&aacute; no asume ninguna responsabilidad por el funcionamiento del mPos. No se garantiza su acceso y uso continuado o ininterrumpido o que el mismo est&eacute; exento de errores y/o defectos.</span></p>
        <p className="c189"><span className="c5">Ual&aacute; no ser&aacute; responsable por los da&ntilde;os que sufra el mPos por exclusiva culpa y negligencia del Usuario. &Eacute;stos ser&aacute;n responsabilidad exclusiva del Usuario.</span></p>
        <p className="c11"><span className="c5">Ual&aacute; no ser&aacute; responsable por el debido cumplimiento de los servicios de terceros o da&ntilde;os ocasionados por ocasionados por terceros prestadores de servicios de acceso a Internet, telefon&iacute;a celular y/o cualquier otro servicio otorgado por personas distintas a Ual&aacute;.</span></p>
        <p className="c61"><span className="c5">En ning&uacute;n caso Ual&aacute; ser&aacute; responsable de cualquier da&ntilde;o incluyendo, de manera enunciativa m&aacute;s no limitativa, da&ntilde;os directos y/o indirectos, perjuicios y/o p&eacute;rdida de oportunidades de negocio que resulten del uso y/o de la imposibilidad de uso de la App y/o P&aacute;gina Web, de los servicios o de los contenidos.</span></p>
        <p className="c45"><span className="c5">Ual&aacute; excluye toda responsabilidad por los da&ntilde;os y perjuicios de toda naturaleza que pudieran deberse al accionar de terceros no autorizados respecto de los datos personales de los usuarios, as&iacute; como de los contenidos y servicios ofrecidos a trav&eacute;s del mPos.</span></p>
        <p className="c131"><span className="c5">En ning&uacute;n caso, Ual&aacute; ser&aacute; responsable de cualquier impuesto a cargo del Usuario por la realizaci&oacute;n de sus actividades econ&oacute;micas, a trav&eacute;s del Servicio de Ual&aacute; Bis.</span></p>
        <p className="c205"><span className="c5">Ual&aacute; pondr&aacute; a disposici&oacute;n del Usuario estados de cuenta a trav&eacute;s de la P&aacute;gina Web, los cuales estar&aacute;n disponibles en todo momento, en t&eacute;rminos de las Disposiciones de car&aacute;cter general aplicables a las redes de medios de disposici&oacute;n.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 14. INCUMPLIMIENTOS DEL USUARIO.</Title>
        <p className="c40"><span className="c5">La P&aacute;gina Web, la App, los Servicios y los Contenidos s&oacute;lo podr&aacute;n ser utilizados con fines l&iacute;citos. Ual&aacute; proh&iacute;be espec&iacute;ficamente cualquier utilizaci&oacute;n de la P&aacute;gina Web y la App, los Servicios o los Contenidos para:</span></p>
        <ol type='i'>
          <li className="c20 c98"><span className="c5">Suministrar informaci&oacute;n fraudulenta, datos de identificaci&oacute;n incompletos, falsos o inexactos, ya sea durante el proceso de registro del Usuario o en cualquier momento durante la vigencia de la relaci&oacute;n contractual con Ual&aacute;;</span></li>
          <li className="c45 c20"><span className="c5">Negarse a proporcionar cualquier informaci&oacute;n, documentaci&oacute;n o justificaci&oacute;n de cualquier acci&oacute;n, requerida por Ual&aacute;.</span></li>
          <li className="c20 c169"><span className="c5">Usar cualquier mecanismo para impedir o intentar impedir el adecuado funcionamiento de la P&aacute;gina Web y/o de la App, los Servicios o los Contenidos.</span></li>
          <li className="c20 c196"><span className="c5">Revelar o compartir las Claves con terceras personas, o usar las Claves para prop&oacute;sitos no autorizados.</span></li>
          <li className="c20 c203"><span className="c5">El uso o intento de uso de cualquier m&aacute;quina, software, herramienta, agente u otro mecanismo para navegar o buscar en la P&aacute;gina Web y/o App, los Servicios o los Contenidos que sean distintos a las herramientas de b&uacute;squeda provistos por Ual&aacute; a trav&eacute;s de la P&aacute;gina Web y/o de la Aplicaci&oacute;n.</span></li>
          <li className="c20 c128"><span className="c5">Intentar descifrar, descompilar u obtener el c&oacute;digo fuente de cualquier programa de software de la P&aacute;gina Web y/o de la App.</span></li>
          <li className="c20 c47"><span className="c5">Enviar cualquier tipo de publicidad no solicitada o no autorizada, spam, y/o todo tipo de comunicaci&oacute;n il&iacute;cita.</span></li>
          <li className="c9"><span className="c5">Realizar acciones que contravengan las regulaciones y disposiciones emitidas por el Banco de M&eacute;xico o la Unidad de Inteligencia Financiera y/o cualquier otro organismo p&uacute;blico que corresponda.</span></li>
          <li className="c20 c166"><span className="c5">Cometer delitos, infracciones, contravenciones y/o da&ntilde;os de cualquier tipo, incluyendo discriminaci&oacute;n y/o acoso, a cualquier persona.</span></li>
          <li className="c20 c110"><span className="c5">Incumplimiento de cualquiera de las obligaciones y/o responsabilidades se&ntilde;aladas en los presente T&eacute;rminos.</span></li>
          <li className="c20 c194"><span className="c5">Tener un alto o desproporcionado n&uacute;mero de Contracargos, conforme a los l&iacute;mites se&ntilde;alados por el Adquirente.</span></li>
        </ol>
        <p className="c91"><span className="c5">Ual&aacute; se reserva el derecho de suspender, limitar las transacciones y/o dar de baja a cualquier Cuenta de Usuario que, a exclusivo criterio de Ual&aacute;, no cumpla con los est&aacute;ndares definidos en este Contrato, sin que ello genere derecho a resarcimiento alguno.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 15. MEDIDAS EN CASO DE INCUMPLIMIENTO.</Title>
        <p className="c14"><span className="c5">Ual&aacute; podr&aacute; realizar una serie de medidas (&quot;las Medidas&quot;) con el fin de proteger a Ual&aacute;, los empleados, Usuarios y consumidores, debido a cualquier acci&oacute;n que realice el Usuario seg&uacute;n la CLÁUSULA 14, o a criterio exclusivo de Ual&aacute;.</span></p>
        <p className="c120"><span className="c5">Las Medidas pueden ser, sin que sea entendida de manera limitativa, las siguientes: (i) exigir que el Usuario justifique cualquier operaci&oacute;n realizada que Ual&aacute; considere inusual, sospechosa, fraudulenta, o de mala fe, independientemente de su monto, a fin de proteger a Ual&aacute; y/o sus filiales y/o subsidiarias y/o controlantes y/o controladas y/o vinculadas y/o socios y/o accionistas y/o gerentes y/o administradores y/o directores y/o funcionarios y/o empleados y/o dependientes y/o representantes y/o apoderados y/o apoderados legales y/o asesores y/o cualquier otra persona f&iacute;sica o moral que pudiera estar relacionada con ella, (ii) proceder a inhabilitar, cerrar la/s cuenta/s y/o la baja de Servicio/s de cualquier Usuario por el tiempo que Ual&aacute; considere necesario para analizar y resolver la situaci&oacute;n y sin que ello le genere a Ual&aacute; responsabilidad alguna. A su vez Ual&aacute; se reserva la facultad de iniciar las denuncias y acciones judiciales que correspondan contra el Usuario; (iii) proceder a inhabilitar, cerrar la/s cuenta/s y/o la baja de Servicio/s de cualquier Usuario cuando existan sospechas de ilegalidades, fraude y/ o cualquier otro acto contrario a estos T&eacute;rminos y Condiciones y/o sospechas de violaci&oacute;n de preceptos legales por los cuales Ual&aacute; deba responder; (iv) resolver, en caso de incumplimiento del Usuario, el presente Contrato y consecuentemente dar de baja la Cuenta de Usuario que incumpla estos T&eacute;rminos y Condiciones y/o se tenga sospechas de violaci&oacute;n de preceptos legales por los cuales Ual&aacute; deba responder; y (v) no procesar la transacci&oacute;n de pago.</span></p>
        <p className="c151"><span className="c5">El Usuario responder&aacute; por todos los da&ntilde;os y perjuicios ocasionados a Ual&aacute; por tal incumplimiento y se compromete a defender y sacar en paz y a salvo a Ual&aacute;.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 16. RETENCIÓN.</Title>
        <p className="c65"><span className="c5">Ual&aacute; se reserva el derecho a retener el abono de las ventas del Usuario depositado en la cuenta concentradora de Ual&aacute;, por un periodo m&aacute;ximo de 180 d&iacute;as naturales por temas de alguna controversia.</span></p>
        <p className="c135"><span className="c5">Que, de manera enunciativa m&aacute;s no limitativa, se detallan a continuaci&oacute;n:</span></p>
        <ul>
          <li className="c38 li-bullet-9"><span className="c5">BIN SERIADO.- Transacciones que sus primeros 12 d&iacute;gitos sean consecutivos y se presenten m&aacute;s de dos operaciones continuas en el mismo negocio.</span></li>
          <li className="c180 li-bullet-10"><span className="c5">VENTAS FRACCIONADAS (Dos o m&aacute;s transacciones con un mismo Medio de Pago).</span></li>
          <li className="c114 li-bullet-11"><span className="c5">TRANSACCIONES RELEVANTES (Transacciones que excedan el ticket promedio).</span></li>
          <li className="c6 li-bullet-12"><span className="c5">AFILIACI&Oacute;N SUSPENDIDA.- Negocio suspendido por petici&oacute;n de Prevenci&oacute;n de Fraudes.</span></li>
          <li className="c178 li-bullet-13"><span className="c5">VENTA SOSPECHOSA.- Operaci&oacute;n fuera del rango promedio del monto de una transacci&oacute;n.</span></li>
          <li className="c64 li-bullet-13"><span className="c5">CUENTA BLOQUEADA/CANCELADA.- Cuenta donde son abonadas las ventas tiene un estatus diferente a activado.</span></li>
          <li className="c180 li-bullet-14"><span className="c5">P&Eacute;RDIDA DE CONTROL DEL USUARIO.</span></li>
        </ul>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 17. AUTONOMÍA E INDEPENDENCIA ENTRE LAS PARTES.</Title>
        <p className="c82"><span className="c5">El Usuario y Ual&aacute; son contratistas independientes, sin ninguna relaci&oacute;n societaria o comercial fuera de los presentes T&eacute;rminos y Condiciones, y cada uno de ellos se mantendr&aacute; a salvo de cualquier reclamo derivado de las obligaciones impositivas, laborales y/o previsionales que estuvieran a su cargo. Ninguna de las partes inducir&aacute; a error a persona alguna sobre su car&aacute;cter de parte contractual independiente de la otra ni obligar&aacute; a la otra parte frente a terceros.</span></p>
        <p className="c16"><span className="c5">El Usuario declara expresamente que cuentan con la experiencia, solvencia econ&oacute;mica, equipamiento, personal, infraestructura, permisos, autorizaciones y habilitaciones que resulten necesarios, para el debido cumplimiento de las obligaciones a su cargo. Por lo que, en caso de as&iacute; requerirlo, Ual&aacute; podr&aacute; solicitar en cualquier momento al Usuario exhiba dichos permisos, autorizaciones y habilitaciones necesarias.</span></p>
        <p className="c16"><span className="c5">El incumplimiento de estas obligaciones por el Usuario habilitar&aacute; a Ual&aacute; a rescindir el presente Contrato de pleno derecho en cualquier momento.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 18. AUDITORÍA.</Title>
        <p className="c97"><span className="c5">El Usuario autoriza a Ual&aacute; para que pueda llevar a cabo una auditor&iacute;a en su negocio, ya sea por s&iacute; mismo o a trav&eacute;s de un tercero debidamente acreditado y designado por Ual&aacute; </span><span className="c28">(incluyendo an&aacute;lisis o estudios acerca de la ubicaci&oacute;n y establecimiento del negocio)</span><span className="c5">, en la medida que Ual&aacute; considere necesario para garantizar el cumplimento de las leyes aplicables, las reglas del Adquirente designado por Ual&aacute;, las Redes de Disposici&oacute;n y/o los presentes T&eacute;rminos y Condiciones.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 19. DISPOSICIONES ESPECIALES.</Title>
        <p className="c14"><span className="c5">El Usuario se obliga a seguir en todas las transacciones que se realicen a trav&eacute;s de Medios de Pago las Reglas de Seguridad en tarjetas de cr&eacute;dito, Reglas de Seguridad en tarjetas de d&eacute;bito, Proceso de verificaci&oacute;n al realizar las transacciones con tarjetas de cr&eacute;dito y/o d&eacute;bito, Procedimiento prudencial, Comportamiento sospechoso del Cliente, Qu&eacute; hacer en caso de sospecha. Dichas disposiciones especiales se adjuntan a los presentes T&eacute;rminos bajo el Anexo &ldquo;1&rdquo;, que son parte integrante de &eacute;stos.</span></p>
        <p className="c73"><Text as='span' css={{ textDecorationLine: 'underline' }}>En el caso de que el Usuario realice y/o acepte las transacciones sin apego a las indicaciones</Text><span className="c5">&nbsp;</span><Text as='span' css={{ textDecorationLine: 'underline' }}>se&ntilde;aladas en el p&aacute;rrafo anterior, realiza las mismas bajo su propia cuenta, riesgo y</Text><span className="c5">&nbsp;</span><Text as='span' css={{ textDecorationLine: 'underline' }}>responsabilidad.</Text></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 20. PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES.</Title>
        <p className="c31"><span className="c5">Cuando se registre en la P&aacute;gina Web o en la App, se le pedir&aacute; al Usuario que aporte a Ual&aacute; cierta informaci&oacute;n que incluir&aacute;, a fines identificatorios y de conocimiento del Cliente, entre otras, nombre y apellido, fecha de nacimiento, nacionalidad, sexo, domicilio, n&uacute;mero de tel&eacute;fono celular del dispositivo donde se instala la Aplicaci&oacute;n, una direcci&oacute;n v&aacute;lida de correo electr&oacute;nico, entre otros datos personales.</span></p>
        <p className="c120"><span className="c5">Sin perjuicio de la informaci&oacute;n proporcionada mediante el procesos de alta en la P&aacute;gina Web y/o App, podr&aacute; adicionalmente solicitarse la actualizaci&oacute;n de dichos datos, e informaci&oacute;n y documentaci&oacute;n adicional respecto: a la actividad que realiza; situaci&oacute;n fiscal; el origen de sus ingresos; origen de los productos; giro comercial; autorizaciones y permisos para el desarrollo de la actividad profesional, comercial o industrial; c&eacute;dula profesional; y toda otra informaci&oacute;n que a juicio de Ual&aacute; pudiese resultar necesaria para determinar el ejercicio de una actividad l&iacute;cita.</span></p>
        <p className="c124"><span className="c5">El Usuario acepta y reconoce que Ual&aacute; podr&aacute; compartir los datos personales del Usuario con terceros seg&uacute;n lo se&ntilde;alado en el aviso de privacidad de Ual&aacute; (incluyendo subsidiarias, afiliadas u otras entidades pertenecientes al mismo Grupo Empresarial) en caso que Ual&aacute; considere que compartir dichos datos personales se encuentra ajustado a derecho o resulta obligatorio en virtud de cualquier relaci&oacute;n contractual con el Usuario o con terceros, o en virtud de la legislaci&oacute;n aplicable, cualquier regulaci&oacute;n o proceso legal.</span></p>
        <p className="c85"><span className="c5">Ual&aacute; validar&aacute; los datos y verificar&aacute; la autenticidad de los documentos que en su caso le hubiere proporcionado el Usuario.</span></p>
        <p className="c161"><span className="c5">Todos los datos personales que el Usuario proporciona con motivos del registro a trav&eacute;s de la P&aacute;gina Web o de la App, seg&uacute;n sea el caso, est&aacute;n protegidos en apego a la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares y Aviso de Privacidad que Ual&aacute; pone a su disposici&oacute;n a trav&eacute;s de la Aplicaci&oacute;n y P&aacute;gina Web.</span></p>
        <p className="c99"><span className="c5">El Usuario deber&aacute; notificar a Ual&aacute; a trav&eacute;s del correo electr&oacute;nico </span><span className="c84 c21"><a className="c18" href="mailto:hola@ualabis.com.mx">hola@ualabis.com.mx</a></span><span className="c33 c84"><a className="c18" href="mailto:hola@ualabis.com.mx">&nbsp;</a></span><span className="c5">a la brevedad posible, si existe alg&uacute;n cambio en su nombre, direcci&oacute;n, n&uacute;mero de tel&eacute;fono m&oacute;vil, correo electr&oacute;nico y en general de cualquier informaci&oacute;n personal que sea actualizada o modificada. Ual&aacute;, para realizar cualquier notificaci&oacute;n utilizar&aacute; los datos del Usuario m&aacute;s recientes, que &eacute;l mismo haya proporcionado por cualquier medio, por lo que en caso de omisi&oacute;n de dicha notificaci&oacute;n el Usuario libera de cualquier responsabilidad derivada a Ual&aacute;.</span></p>
        <p className="c85"><span className="c5">Ual&aacute; se reserva el derecho de ofrecer servicios y productos de terceros basados en las preferencias que el Usuario haya indicado al momento de registrarse y/o en cualquier momento posterior; tales ofertas pueden efectuarse por Ual&aacute; o por terceros.</span></p>
        <p className="c50"><span className="c5">El Usuario ser&aacute; responsable de todos los usos de su Cuenta de Usuario, tanto si est&aacute;n autorizados o no por el Usuario. El Usuario deber&aacute; notificar inmediatamente a Ual&aacute; sobre cualquier uso sin autorizaci&oacute;n de su Cuenta de Usuario y/o las Claves.</span></p>
        <p className="c19"><span className="c5">Los Usuarios que utilicen los Servicios de Ual&aacute; Bis garantizan la veracidad, exactitud, vigencia y autenticidad de la informaci&oacute;n facilitada, y se comprometen a mantenerlos debidamente actualizados, informando a Ual&aacute; sobre cualquier modificaci&oacute;n a trav&eacute;s de la actualizaci&oacute;n de la informaci&oacute;n correspondiente en su Cuenta de Usuario, o poni&eacute;ndose en contacto a </span><span className="c84 c21"><a className="c18" href="mailto:hola@ualabis.com.mx">hola@ualabis.com.mx</a></span><span className="c5">.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 21. CONFIDENCIALIDAD.</Title>
        <p className="c56"><span className="c5">La Cuenta de Usuario y las Claves ser&aacute;n personales, secretos, confidenciales e intransferibles, quedando terminantemente prohibida su divulgaci&oacute;n a terceros por parte del Usuario.</span></p>
        <p className="c131"><span className="c5">En caso de que el Usuario llegase a saber y/o sospechar sobre una vulneraci&oacute;n de la confidencialidad de sus Claves, deber&aacute; modificarla de inmediato.</span></p>
        <p className="c205"><span className="c5">El Usuario ser&aacute; el &uacute;nico y exclusivo responsable de la confidencialidad de sus Claves, as&iacute; como tambi&eacute;n de todas las operaciones y/o actividades llevadas a cabo con las Claves y la Cuenta de Usuario.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 22. PROPIEDAD INTELECTUAL E INDUSTRIAL.</Title>
        <p className="c31"><span className="c5">Los contenidos de la P&aacute;gina Web y de la App, tales como texto, informaci&oacute;n, gr&aacute;ficos, im&aacute;genes, logos, marcas, programas de computaci&oacute;n, bases de datos, dise&ntilde;os, APIs, arquitectura funcional y cualquier otro material (en adelante, &quot;el Contenido&quot;), est&aacute;n protegidos por las leyes vigentes en la Rep&uacute;blica Mexicana, incluyendo, pero sin limitaci&oacute;n, las leyes sobre derechos de autor, patentes, marcas, modelos de utilidad, dise&ntilde;os industriales y nombres de dominio, se encuentren o no registrados, en el pa&iacute;s o en el exterior.</span></p>
        <p className="c120"><span className="c5">Todo el contenido es propiedad de Ual&aacute; y/o de cualquier otra sociedad filial, subsidiaria, sucursal, agencia, representaciones, controlantes, controladas, vinculadas y cualquier persona f&iacute;sica o moral que pudiera estar relacionada con Ual&aacute; y su contenido. La compilaci&oacute;n, interconexi&oacute;n, operatividad y disposici&oacute;n de los contenidos de la P&aacute;gina Web y de la App son propiedad exclusiva de Ual&aacute; y/o de sus empresas vinculadas.</span></p>
        <p className="c124"><span className="c5">El Usuario se encuentra autorizado a utilizar la marca y logotipo de Ual&aacute; Bis en acciones promocionales y/o publicitarias que tenga exclusiva relaci&oacute;n con el Servicio Ual&aacute; Bis. Dicha autorizaci&oacute;n se mantendr&aacute; siempre y cuando se respete la naturaleza de la misma y podr&aacute; ser revocada por Ual&aacute; en cualquier momento a su exclusivo criterio. El uso, adaptaci&oacute;n, reproducci&oacute;n y/o comercializaci&oacute;n no autorizada del contenido puede encontrarse penado por la legislaci&oacute;n vigente en la Rep&uacute;blica Mexicana.</span></p>
        <p className="c120"><span className="c5">El Usuario no copiar&aacute; ni adaptar&aacute; el c&oacute;digo de programaci&oacute;n desarrollado por, o por cuenta de, Ual&aacute; para generar y operar la P&aacute;gina Web y/o App o los Servicios, el cual se encuentra protegido por la legislaci&oacute;n aplicable y vigente en la Rep&uacute;blica Mexicana.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 23. PROPIEDAD INTELECTUAL E INDUSTRIAL.</Title>
        <p className="c72"><span className="c5">Ual&aacute; se reserva el derecho a modificar, restringir y/o suprimir todos o cualquiera de los Servicios, en forma temporal o definitiva, sin que estas medidas puedan ser objeto de requerimiento alguno, ni de derecho a reclamar da&ntilde;os o perjuicios por parte del Usuario.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 24. MODIFICACIÓN DE LOS TÉRMINOS Y CONDICIONES.</Title>
        <p className="c65"><span className="c5">Ual&aacute; podr&aacute; modificar en cualquier momento los T&eacute;rminos y Condiciones notificando previamente los cambios al Usuario v&iacute;a mail y/o notificaci&oacute;n push en la App y publicando una versi&oacute;n actualizada de dichos T&eacute;rminos y Condiciones en la P&aacute;gina Web </span><span className="c125 c123 c33 c137"><a className="c18" href="https://www.google.com/url?q=http://www.ualabis.com.mx/&amp;sa=D&amp;source=editors&amp;ust=1691676535765463&amp;usg=AOvVaw1qsFqYScgOtge1nqDMl0UO">www.ualabis.com.mx</a></span><span className="c123 c33 c125"><a className="c18" href="https://www.google.com/url?q=http://www.ualabis.com.mx/&amp;sa=D&amp;source=editors&amp;ust=1691676535765744&amp;usg=AOvVaw1CCKNBVkvMhSzGEVoE1Wg5">&nbsp;</a></span><span className="c5">con expresi&oacute;n de la fecha de la &uacute;ltima modificaci&oacute;n. Todos los t&eacute;rminos modificados entrar&aacute;n en vigor en la fecha indicada en la notificaci&oacute;n. Dentro de los 10 (diez) d&iacute;as naturales siguientes a la notificaci&oacute;n de las modificaciones realizadas, el Usuario podr&aacute; comunicar por e-mail si no acepta las mismas; en ese caso quedar&aacute; terminada la relaci&oacute;n contractual, excepto cuando existan obligaciones pendientes de cumplimiento, en cuyo caso no podr&aacute; darse por terminado, hasta en tanto se cumplan dichas obligaciones. Terminado dicho plazo, se considerar&aacute; que el Usuario acepta los nuevos T&eacute;rminos y Condiciones y el Contrato continuar&aacute; vigente para ambas partes.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 25. CESIÓN O USO COMERCIAL NO AUTORIZADO.</Title>
        <p className="c82"><span className="c5">El Usuario acepta no ceder, bajo ning&uacute;n t&iacute;tulo, sus derechos u obligaciones bajo el presente Contrato. El Usuario tambi&eacute;n acepta que no realizar&aacute; alg&uacute;n uso comercial no autorizado de la P&aacute;gina Web, de la App, de los Servicios o de los Contenidos.</span></p>
        <p className="c112"><span className="c5">Asimismo, el Usuario se compromete a utilizar la P&aacute;gina Web, la App, el Contenido y los Servicios diligentemente y de conformidad con las leyes aplicables y vigentes en la Rep&uacute;blica Mexicana y conforme a este Contrato.</span></p>
        <p className="c45"><span className="c5">Ual&aacute; se reserva el derecho, a su exclusivo criterio, de emplear todos los medios legales a su alcance en caso de que el Usuario infrinja cualquiera de estos T&eacute;rminos y Condiciones.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 26. VIGENCIA. TERMINACIÓN ANTICIPADA Y RESCISIÓN DEL SERVICIO.</Title>
        <p className="c69"><Text as="span" weight='bold'>Plazo. </Text><span className="c5">Los presentes T&eacute;rminos y condiciones tienen una duraci&oacute;n indeterminada.</span></p>
        <p className="c65"><Text as="span" weight='bold'>Terminaci&oacute;n Anticipada. </Text><span className="c5">Sin perjuicio de lo anterior, Ual&aacute; y el Usuario podr&aacute;n terminar anticipadamente la relaci&oacute;n jur&iacute;dica derivada de estos T&eacute;rminos y Condiciones, sin responsabilidad alguna y sin mediar declaraci&oacute;n u orden judicial, excepto cuando existan obligaciones pendientes de cumplimiento, en cuyo caso no podr&aacute; darse por terminado, hasta en tanto se cumplan dichas obligaciones; debiendo &uacute;nicamente notificar fehacientemente su decisi&oacute;n con al menos 10 (diez) d&iacute;as naturales de antelaci&oacute;n.</span></p>
        <p className="c83"><span className="c5">La solicitud de cierre de la Cuenta de Usuario deber&aacute; ser realizada por el Usuario a trav&eacute;s de los canales de comunicaci&oacute;n.</span></p>

        <p>
          <Text weight='bold' as='span'>Rescisi&oacute;n</Text><span className="c5">: Sin perjuicio de lo indicado y lo dispuesto en la CLÁUSULA 15 y en los presentes T&eacute;rminos y Condiciones, Ual&aacute; se reserva el derecho de rescindir unilateralmente el Contrato, sin responsabilidad alguna y sin mediar declaraci&oacute;n u orden judicial, en los siguientes casos:</span>
        </p>
        <ol type='i'>
          <li className="c59 li-bullet-15"><span className="c5">Incumplimiento de estos T&eacute;rminos y Condiciones por parte del Usuario;</span></li>
          <li className="c20 c65"><span className="c5">si es declarada o pedida la quiebra del Usuario, o el Usuario se presenta en concurso mercantil, o es inhabilitado para operar o afectado por cualquier otra situaci&oacute;n que haga presumir su insolvencia;</span></li>
          <li className="c20 c87"><span className="c5">si el Usuario recibe denuncias por los consumidores o incumple cualquier disposici&oacute;n jur&iacute;dica vigente respecto a la protecci&oacute;n a los consumidores y usuarios;</span></li>
          <li className="c22 c20"><span className="c5">si surgen indicios o se confirma que el Usuario est&aacute; participando o ha participado en actividades fraudulentas, delictivas y/o il&iacute;citas;</span></li>
          <li className="c20 c157"><span className="c5">si no re&uacute;ne cualquiera de los requisitos y/o no cuenta con la documentaci&oacute;n necesaria para ser Usuario.</span></li>
        </ol>

        <p className="c2"><span className="c5">A&uacute;n despu&eacute;s de dicha terminaci&oacute;n o rescisi&oacute;n, el Usuario deber&aacute; hacer frente a las obligaciones que resulten a su cargo derivadas de los presentes T&eacute;rminos por el tiempo necesario hasta su resoluci&oacute;n final.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 27. ANEXOS. TOTALIDAD DEL CONTRATO.</Title>
        <p className="c191"><span className="c5">Los siguientes documentos, forman parte integral e inseparable de los presentes T&eacute;rminos y Condiciones:</span></p>
        <ul className="c1 lst-kix_list_2-0 start">
          <li className="c121 li-bullet-16"><span className="c5">Aviso de Privacidad</span></li>
          <li className="c88 li-bullet-10"><span className="c5">T&eacute;rminos y Condiciones de Uso de Ual&aacute; Bis.</span></li>
          <li className="c29 li-bullet-17"><span className="c5">Anexos</span></li>
        </ul>
        <p className="c118"><span className="c5">Se podr&aacute;n consultar dentro del sitio mediante el enlace abajo provisto o accediendo directamente a </span><span className="c125 c21 c90 c129"><a className="c18" href="mailto:hola@ualabis.com.mx">hola@ualabis.com.mx</a></span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 28. COMUNICACIONES.</Title>
        <p className="c72"><span className="c5">Para consultas y/o reclamos generales relacionados espec&iacute;ficamente con el Servicio Ual&aacute; Bis, el Usuario deber&aacute; comunicarse a trav&eacute;s de la siguiente casilla de correo electr&oacute;nico: </span><span className="c125 c21 c90 c129"><a className="c18" href="mailto:hola@ualabis.com.mx">hola@ualabis.com.mx</a></span><span className="c5"><a className="c18" href="mailto:hola@ualabis.com.mx">.</a></span></p>
        <p className="c14"><span className="c5">Para env&iacute;o, actualizaci&oacute;n y/o modificaci&oacute;n de documentaci&oacute;n soporte de su registro, incluida la fiscal, el Usuario deber&aacute; comunicarse a trav&eacute;s de la siguiente casilla de correo electr&oacute;nico: </span><span className="c125 c21 c90 c129"><a className="c18" href="mailto:hola@ualabis.com.mx">hola@ualabis.com.mx</a></span></p>
        <p className="c142"><span className="c5">Los presentes son complementarios al resto de los canales oficiales de comunicaci&oacute;n establecidos por Ual&aacute; para contacto con los Usuarios.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 29. DOMICILIO. LEY APLICABLE. COMPETENCIA.</Title>
        <p className="c200"><span className="c5">Estos T&eacute;rminos y Condiciones se rigen por las leyes vigentes en los Estados Unidos Mexicanos. Toda acci&oacute;n derivada de estos T&eacute;rminos y Condiciones o del uso de la P&aacute;gina</span></p>
        <p className="c19"><span className="c5">Web y de la App deber&aacute; someterse exclusivamente a la jurisdicci&oacute;n de los tribunales ubicados en la Ciudad de M&eacute;xico, renunciando las partes a cualquier otro fuero o jurisdicci&oacute;n que pudiera corresponder.</span></p>
        <p className="c45"><span className="c5">En caso de declararse la nulidad o invalidez de alguna de las CLÁUSULAs de este Contrato, dicha nulidad no afectar&aacute; la validez de las restantes CLÁUSULAs, las cuales mantendr&aacute;n su plena vigencia y efecto.</span></p>

        <Title as='h2' size='md' css={{ textDecorationLine: 'underline' }}>CLÁUSULA 30. AGREGADOR DE PAGOS.</Title>
        <p className="c14"><span className="c5">Ual&aacute; es un Agregador que ofrece servicios a trav&eacute;s de las Redes de Medios de Disposici&oacute;n en t&eacute;rminos de las Disposiciones de car&aacute;cter general aplicables a las redes de medios de disposici&oacute;n.</span></p>
        <Text weight='bold' color='information' css={{ marginBottom: 500 }}>Última actualización: 31 de marzo de 2023.</Text>





        <Title as='h2' size='sm' align='center'>ANEXO “1”</Title>
        <Title as='h3' size='sm' align='center'>DISPOSICIONES ESPECIALES</Title>

        <Title as='h4' size='sm' align='center'>I. Reglas de seguridad en las tarjetas de crédito:</Title>
        <ol type='a'>
          <li className="c159 li-bullet-4"><span className="c5">El personal del Usuario deber&aacute; verificar en todas las transacciones con tarjeta de cr&eacute;dito:</span></li>
          <ul>
            <li className="c152 li-bullet-13"><span className="c5">Que la numeraci&oacute;n de las tarjetas VISA comiencen con un 4 (cuatro) y las de MASTERCARD con un 5 (cinco).</span></li>
            <li className="c181 li-bullet-19"><span className="c5">Que en las tarjetas VISA que as&iacute; lo tengan bajo la luz negra se observe una letra V en el logotipo de VISA</span></li>
            <li className="c176 li-bullet-12"><span className="c5">Que los primeros 4 (cuatro) d&iacute;gitos embozados en su anverso de las tarjetas VISA y MASTERCARD deben coincidir con los 4 (cuatro) d&iacute;gitos preimpresos que aparecen arriba o debajo de los embozados.</span></li>
            <li className="c188 li-bullet-13"><span className="c5">Que las tarjetas VISA tengan en su anverso un holograma con la imagen de una paloma que debe dar apariencia tridimensional y cambiar de colores al mover la tarjeta. Asimismo, los &uacute;ltimos 4 (cuatro) d&iacute;gitos de la numeraci&oacute;n de la tarjeta deben estar embozados sobre el holograma. El holograma tambi&eacute;n puede encontrarse en el reverso de la tarjeta en la versi&oacute;n mini con las mismas caracter&iacute;sticas mencionadas anteriormente.</span></li>
            <li className="c23 li-bullet-13"><span className="c5">Que las tarjetas MASTERCARD tengan en su anverso un holograma con globos terr&aacute;queos entrelazados que muestran los continentes en tercera dimensi&oacute;n al moverse la tarjeta, asimismo la palabra MASTERCARD aparecer&aacute; repetida en cada una de las l&iacute;neas al fondo.</span></li>
            <li className="c143 li-bullet-13"><span className="c5">Que las tarjetas MASTERCARD tengan en su reverso una cintilla para firma, en la cual deber&aacute; estar impresa en forma repetida la palabra MASTERCARD, en multicolores en un &aacute;ngulo de 45&deg;.</span></li>
            <li className="c186 li-bullet-0"><span className="c5">Que las tarjetas VISA al pasar por la luz negra en su reverso en el panel de firma sea visible la palabra VISA o el patr&oacute;n VOID en forma repetida.</span></li>
            <li className="c168 li-bullet-13"><span className="c5">Que los &uacute;ltimos 4 (cuatro) d&iacute;gitos embozados en el anverso de las tarjetas VISA y MASTERCARD deben coincidir con los 4 (cuatro) d&iacute;gitos preimpresos que aparecen en el reverso en el panel de firma y a un costado de este se encontrar&aacute;n impresos los 3 d&iacute;gitos de seguridad.</span></li>
          </ul>
        </ol>

        <Title as='h4' size='sm'>II. Reglas de seguridad en las tarjetas de débito:</Title>
        <ol type='a' start={2}>
          <li>
            <span>
              El personal del Usuario deberá verificar en todas las transacciones con tarjetas de débito:
            </span>
          </li>
          <ul>
            <li>Que las tarjetas VISA (ELECTRON) comiencen con un 4 (cuatro) y las MASTERCARD (MAESTRO) con un 5 (cinco).</li>
            <li>Que las tarjetas VISA traigan el logotipo ELECTRON y las MASTERCARD el logotipo de MAESTRO.</li>
            <li>Que las tarjetas ELECTRON traigan la leyenda de “ELECTRONIC USE ONLY” en el anverso o en el reverso.</li>
            <li>Que la Información de la cuenta en las tarjetas ELECTRON, como el número, nombre y vencimiento de la tarjeta está impresa en el anverso de la tarjeta y no embozada.</li>
            <li>La Información de la cuenta en las tarjetas MAESTRO, como el número, nombre y vencimiento de la tarjeta puede estar embozada en el anverso, pero no se le permite al Usuario transcribirla en ningún formato.</li>
            <li>Que los primeros 4 (cuatro) dígitos impresos en el anverso de las tarjetas ELECTRON deben coincidir con los 4 (cuatro) dígitos preimpresos que aparecen arriba o abajo de los grabados.</li>
            <li>Que las tarjetas ELECTRON al pasar por la luz negra en su reverso en el panel de firma sea visible la palabra VISA de forma repetida.</li>
            <li>Que las tarjetas MAESTRO tengan en su reverso una cintilla para firma, en la cual deberá contener únicamente la firma del tarjetahabiente.</li>
          </ul>
        </ol>

        <Title as='h4' size='sm'>III. Proceso de verificación al realizar la transacción con tarjetas de crédito y/o débito:</Title>
        <ol type='a' start={3}>
          <li><span>El Usuario deberá verificar en todas las transacciones con tarjetas de crédito y/o débito:</span></li>
          <ul>
            <li className="c34 li-bullet-13"><span className="c5">Que el nombre embozado o impreso en el anverso de la tarjeta corresponda al sexo de la persona que suscribe el pagar&eacute;.</span></li>
            <li className="c102 li-bullet-25"><span className="c5">Que la operaci&oacute;n se realice dentro de la fecha de vigencia que aparece en el anverso de la tarjeta por debajo de la leyenda &ldquo;vence&rdquo; o &ldquo;</span><span className="c33 c106">good thru</span><span className="c5">&rdquo;.</span></li>
            <li className="c141 li-bullet-26"><span className="c5">Que cuando reciba una tarjeta con fotograf&iacute;a verificar&aacute; que la persona que ah&iacute; aparece sea la misma que est&aacute; realizando la transacci&oacute;n.</span></li>
            <li className="c92 li-bullet-12"><span className="c5">Que los pagar&eacute;s que deseche los destruya completamente tanto en su original, copias y papel carb&oacute;n utilizados en los mismos.</span></li>
            <li className="c136 li-bullet-19"><span className="c5">Que se anote en el pagar&eacute; la fecha completa que corresponda al d&iacute;a en que solicit&oacute; la autorizaci&oacute;n de la transacci&oacute;n.</span></li>
            <li className="c160 li-bullet-26"><span className="c5">La cintilla de firma que aparece al reverso de la tarjeta, no debe presentar tachaduras, enmendaduras, borraduras o se&ntilde;ales de firmas encimadas, ni presentar las palabras: &ldquo;</span><span className="c106 c33">VOID</span><span className="c5">&rdquo; o &ldquo;NULA&rdquo;.</span></li>
            <li className="c187 li-bullet-13"><span className="c5">Que los pagar&eacute;s y documentos de devoluci&oacute;n, no sean llenados, ni enmendados, los datos relativos a la tarjeta a el Usuario, mediante m&aacute;quina de escribir o en letra manuscrita.</span></li>
            <li className="c212 li-bullet-9"><span className="c5">Que se conserve la tarjeta durante toda la operaci&oacute;n y se coteje la firma que se estampa en el pagar&eacute; con la que aparece en la tarjeta, cercior&aacute;ndose de que sean iguales.</span></li>
            <li className="c155 li-bullet-27"><span className="c5">En el caso de recibir pagos con tarjetas de bancos extranjeros, deber&aacute; observar adem&aacute;s de lo previsto en este contrato, que el tarjetahabiente se identifique con documento oficial con foto y firma (pasaporte o licencia de conducir) al momento de realizar la transacci&oacute;n.</span></li>
            <li className="c138 li-bullet-13"><span className="c5">Procurar retener las tarjetas por medios pac&iacute;ficos y sin arriesgar la integridad f&iacute;sica, y entregarlas a el Adquirente cuando le sean presentadas tarjetas de las que el propio procesador de tarjetas o el Adquirente, le hubieren solicitado retener o le hubieren dado aviso por escrito de su cancelaci&oacute;n</span></li>
            <li className="c206 li-bullet-13"><span className="c5">Validar, cuando se pida la autorizaci&oacute;n a trav&eacute;s del mPos, que el n&uacute;mero impreso en la tarjeta, sea el mismo que aparece en la pantalla de la terminal al deslizar o insertar la tarjeta en el mPos.</span></li>
          </ul>
        </ol>
        <p className="c120"><span className="c5">El Usuario no deber&aacute; aceptar ninguna transacci&oacute;n con tarjeta en donde las tarjetas no cumplan con las caracter&iacute;sticas de seguridad enunciadas en los numerales I y II del presente Anexo y adem&aacute;s deber&aacute; llamar por tel&eacute;fono al procesador de tarjetas cuando considere sospechosa cualquier compra o la actitud del Cliente (solicitud &ldquo;autorizaci&oacute;n c&oacute;digo 10 (diez), a trav&eacute;s de la cual expondr&aacute; el caso y deber&aacute; seguir las instrucciones que le indique el procesador de tarjetas.</span></p>
        <p className="c112"><span className="c5">En caso de que el Usuario acepte por s&iacute; mismo y sin apego al procedimiento de autorizaci&oacute;n aqu&iacute; indicado, las tarjetas de referencia al inicio de este p&aacute;rrafo, lo har&aacute; bajo su propia cuenta, riesgo y responsabilidad.</span></p>

        <Title as='h4' size='sm'>IV. Procedimiento prudencial</Title>
        <ul className="c1 lst-kix_list_1-1">
          <li className="c101 li-bullet-26"><span className="c5">La tarjeta de cr&eacute;dito, debe estar completamente lisa en el lugar donde est&aacute; el holograma y el logotipo de VISA o MASTERCARD. Estos no deben de estar abultados, adheridos o sobrepuestos en la tarjeta, advirtiendo en su caso dichas irregularidades, a simple vista o contacto con la tarjeta.</span></li>
          <li className="c63 li-bullet-26"><span className="c5">Las tarjetas no se decoloran al contacto a&uacute;n friccion&aacute;ndolas con firmeza sobre un papel.</span></li>
          <li className="c150 li-bullet-13"><span className="c5">Se deben observar los n&uacute;meros embozados por ambos lados de la tarjeta y estos deben estar completamente claros. No deben aparecer marcas como si se hubieran aplanado y vuelto a realzar.</span></li>
          <li className="c24 li-bullet-0"><span className="c5">No se deben aceptar pagar&eacute;s que fueron llenados fuera del (de los) establecimiento(s) del Usuario. El tarjetahabiente y la tarjeta siempre deben estar presentes al momento de la transacci&oacute;n.</span></li>
          <li className="c94 li-bullet-13"><span className="c5">No se aceptar&aacute;n pagar&eacute;s suscritos en establecimientos distintos al(los) autorizado(s) por el Adquirente.</span></li>
        </ul>

        <Title as='h4' size='sm'>V. Comportamiento sospechoso del Cliente:</Title>
        <ol type='a' start={4}>
          <li>Se debe observar el comportamiento de los Clientes y en especial:</li>
          <ul>
            <li className="c127 li-bullet-31"><span className="c5">Cuando muestra se&ntilde;ales de nerviosismo al momento de firmar el pagar&eacute; o se lleva demasiado tiempo, ya que no se ha aprendido la firma.</span></li>
            <li className="c89 li-bullet-32"><span className="c5">Compra en varias ocasiones art&iacute;culos de precio reducido.</span></li>
            <li className="c75 li-bullet-33"><span className="c5">Compra un producto de precio bajo para probar la tarjeta y despu&eacute;s adquiere otro de un precio mayor.</span></li>
          </ul>
        </ol>

        <Title as='h4' size='sm'>VI. ¿Qué hacer en caso de sospecha?:</Title>
        <ul className="c1 lst-kix_list_1-1">
          <li className="c185 li-bullet-27"><span className="c5">Cuando cumpla alguno de puntos enunciados en los numerales &ldquo;II&rdquo;, &ldquo;III&rdquo; y &ldquo;IV&rdquo; del presente Anexo o exista alguna irregularidad en la transacci&oacute;n o sospecha de que pudiera tratarse de una tarjeta falsa o robada el Usuario no debe aceptar la transacci&oacute;n y debe llamar al procesador de tarjetas solicitando una autorizaci&oacute;n &ldquo;c&oacute;digo 10 (diez)&rdquo; informando el caso o su sospecha y seguir las instrucciones que le indique el procesador de tarjetas.</span></li>
          <li className="c204 li-bullet-35"><span className="c5">Solicitar identificaci&oacute;n al Cliente y advertir la autenticidad de la misma, ya que pudiera ser falsa.</span></li>
          <li className="c173 li-bullet-26"><span className="c5">Si el sospechoso se retira del establecimiento y se cuenta con informaci&oacute;n para su localizaci&oacute;n, tales como su media filiaci&oacute;n, domicilio, placas del autom&oacute;vil en el que se retir&oacute; del lugar, etc&eacute;tera, debe informarlo al procesador de tarjetas.</span></li>
        </ul>
        <p className="c133"><span className="c5">En el caso de que el Usuario realice transacciones sin apego a lo se&ntilde;alado en los T&eacute;rminos y el presente Anexo, realiza las mismas bajo su propia cuenta, riesgo y responsabilidad.</span></p>
      </>
    </FormLayout >
  )
}

export default TyC
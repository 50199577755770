import useWindowSize from './useWindowSize';

type Props = {
  breakpoint?: number;
};

const useDesktopSize = ({ breakpoint = 740 }: Props = {}): boolean => {
  const { width } = useWindowSize();

  return width > breakpoint;
};

export const useGetDevice = () =>
  useDesktopSize({ breakpoint: 1200 }) ? 'desktop' : 'mobile';

export default useDesktopSize;

import styled from 'styled-components';

const LeftArrowContainer = styled.div`
  display: flex;
  align-self: flex-start;
  min-height: 28px;
  width: 100%;

  img {
    width: 28px;
    height: 28px;

    :hover {
      cursor: pointer;
    }
  }

  @media (max-width: 1200px) {
    margin: 0;
    min-height: 25px;
    img {
      width: 25px;
      height: 25px;
    }
  }
`;

export { LeftArrowContainer };

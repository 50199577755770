import { onboardingInstance } from '../../shared/axios';
import { endpoints } from '../../shared/constants';

type SignupForm = {
  email: string;
  password: string;
  personType: string;
  token: string;
};

const postSignupUser = async (userData: SignupForm) => {
  const response = await onboardingInstance.post(endpoints.SIGNUP_USER_FORM, {
    Email: userData.email,
    Password: userData.password,
    UserType: userData.personType,
    CaptchaToken: userData.token
  });

  return response.data;
};

const getRegistrationStatus = async (email: string) => {
  const endpoint = endpoints.REGISTRATION_STATUS.replace('{email}', email);
  const response = await onboardingInstance.get(endpoint);

  return response.data;
};

export { postSignupUser, getRegistrationStatus };

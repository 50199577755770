import { Button } from '@uala/ui';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 100%;
  align-self: center;
  justify-content: center;

  @media (min-width: 1200px) {
    &.information-page {
      margin: unset;
    }
  }
`;

export const SubmitButton = styled(Button)`
  max-width: 546px;
  width: 100%;
  height: 48px;
  border-radius: 30px;
  @media (max-width: 1200px) {
    max-width: 327px;
  }
  @media (max-width: 342px) {
    max-width: 90%;
  }
`;

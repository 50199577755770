const eventList: { [index: string]: string } = {
  '/': 'registro_cobros_visualiza_primer_paso',
  '/about-ppmm': 'registro_cobros_info_ppmm',
  '/pending-approval': 'registro_cobros_finaliza_registro',
  '/finished-onboarding': 'registro_cobros_mail_registrado_registro_completo',
};

export const getEventNameByPathname = () => {
  const { pathname } = window.location;
  return eventList.hasOwnProperty(pathname) ? eventList[pathname] : '';
};

export const getOptionsByPathname = (formData: any) => {
  const { pathname } = window.location;
  const options: any = {};
  if (!!formData) {
    switch (pathname) {
      case '/first-step/2':
        options.rol_empresa = formData.companyPosition;
        options.pep = formData.pep;
        options.impuestos_otro_pais = formData.foreignTaxes;
        break;
      case '/second-step/1':
        options.tipo_sociedad = formData.businessType;
        break;
      case '/second-step/3':
        options.iibb = formData.grossEarnings;
        options.tipo_iibb = formData.taxType;
        break;
      case '/second-step/4':
        options.uif = formData.obligatedSubject;
        options.impuestos_otro_pais = formData.foreignTaxesLast;
        break;
    }
  }
  return options;
};

export const getOptionsByUrlSearch = (eventName: string) => {
  const { search } = window.location;
  const options: any = {};
  const urlParams = new URLSearchParams(search);
  switch (eventName) {
    case 'registro_ppjj_visualiza_primer_paso':
    case 'registro_ppjj_landing_page_mobile':
      if (urlParams.has('af_sub1')) {
        options.referrer_id = urlParams.get('af_sub1');
      }
      break;
  }
  return options;
};

import React from 'react';
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route
} from 'react-router-dom';
import CreateAccount from './features/CreateAccount';
import DataRequest from './features/DataRequest';
import Onboarding from './features/Onboarding';
import PendingApproval from './features/PendingApproval';
import ValidateEmail from './features/ValidateEmail';
import ReturnOnboarding from './features/ReturnOnboarding';
import FinishedOnboardingPendingValidate from './features/FinishedOnboardingPendingValidate';
import SelectPersonType from './features/SelectPersonType';
import AboutPpmm from './features/AboutPpmm/AboutPpmm';
import TyC from './features/TyC';
import GeolocationDenied from './features/GeolocationDenied';

const Routes: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" element={<SelectPersonType />} />
        <Route path="tyc" element={<TyC />} />
        <Route path="/about-ppmm" element={<AboutPpmm />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/return-onboarding" element={<ReturnOnboarding />} />
        <Route
          path="/finished-onboarding"
          element={<FinishedOnboardingPendingValidate />}
        />
        <Route path="/validate-email" element={<ValidateEmail />} />
        <Route path="/data-request" element={<DataRequest />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/pending-approval" element={<PendingApproval />} />
        <Route path="/geolocation-denied" element={<GeolocationDenied />} />
      </Switch>
    </Router>
  );
};

export default Routes;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlElement } from '../../types';
import { Icon, RowWrapper, Text, Wrapper } from './styles';
import { PasswordValidation } from './types';

export const PasswordHelper: React.FC<ControlElement> = ({
  passwordHelper
}) => {
  const { watch } = useFormContext();
  const password = watch(passwordHelper!.passwordInputName);

  const getIcon = (pv: { pattern: string; message: string }) => {
    let regexPattern = new RegExp(pv.pattern);
    if (!regexPattern.test(password)) {
      return (
        <Icon
          src={'images/icon-circle-error.svg'}
          alt={`Que tenga ${pv.message} no cumplido.`}
        />
      );
    } else {
      return (
        <Icon
          src={'images/icon-circle-valid.svg'}
          alt={`Que tenga ${pv.message} cumplido.`}
        />
      );
    }
  };

  return (
    <>
      {password && password !== '' && (
        <Wrapper>
          {Object(passwordHelper!.passwordValidations).map(
            (pv: PasswordValidation, index: number) => {
              return (
                <RowWrapper key={index}>
                  {getIcon(pv)}
                  <Text>{pv.message}</Text>
                </RowWrapper>
              );
            }
          )}
        </Wrapper>
      )}
    </>
  );
};

import styled from "styled-components";

export const CustomButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;
  width: 100%;

  &.information-page {
    @media (min-width: 1200px) {
      margin-top: unset;
    }
  }
`
import moment from 'moment';
import { UseFormReturn } from 'react-hook-form';
import { CustomValidationProps } from '../../components/GenericForm/types';
import bankCodes from './data/bankCodes.json';

const birthday = async ({ input }: CustomValidationProps) => {
  input.validations = {
    ...input.validations,
    validate: (value: string) => {
      const date = moment(value, 'DD/MM/YYYY');
      const now = moment();
      if (!date.isValid()) {
        return 'Fecha inválida.';
      } else if (now.diff(date, 'years') < 18) {
        return 'Debes ser mayor de 18 años.';
      }
    }
  };
};

const formationDate = async ({ input }: CustomValidationProps) => {
  input.validations = {
    ...input.validations,
    validate: (value: string) => {
      const date = moment(value, 'DD/MM/YYYY');
      if (!date.isValid() || date.isAfter(moment())) {
        return 'Fecha inválida.';
      }
    }
  };
};

const federalEntityOfBirth = async ({
  input,
  methods
}: CustomValidationProps) => {
  if (typeof input.render !== 'undefined') {
    if (methods!.watch('countryOfBirth') === 'México' || methods!.watch('ownerCountryOfBirth') === 'México') {
      input.render = true;
    } else {
      input.render = false;
      if (methods!.getValues(input.name) !== '')
        methods!.setValue(input.name, '');
    }
  }
};

const phoneNumber = async ({ input }: CustomValidationProps) => {
  input.validations = {
    ...input.validations,
    validate: (value: string) =>
      !/^0/.test(value) || 'Sin el 0 (ej: 55 23885434).'
  };
};

const getClabeDescription = (value: string) => {
  return `${value.replaceAll(' ', '').length}/18 dígitos ingresados`;
};

const clabe = async ({
  input,
  methods
}: CustomValidationProps) => {
  const clabe: string = methods!.watch('clabe');
  if (clabe) {
    const cil = clabe.replaceAll(' ', '').length;
    input.validations = {
      ...input.validations,
      validate: (value: string) => {
        input.description = getClabeDescription(value);
        return cil === 18 || input.description;
      }
    };

    let bankCodeInput: string;
    let bankName: string | undefined = '';
    if (clabe?.length >= 3) {
      bankCodeInput = clabe?.substring(0, 3);
      if (bankCodeInput.length === 3 && !bankCodeInput.includes(' ')) {
        bankName = bankCodes.find(
          (bankCode) => bankCode.code === bankCodeInput
        )?.name;
      }
    }
    methods!.setValue('bank', bankName);
  } else {
    input.description = 'Son 18 digitos.';
  }
};

const provinces = async (
  { input }: CustomValidationProps,
  loadCities: (city: string, methods: UseFormReturn) => void
) => {
  input.handleSuperChange = loadCities;
};

const cities = async (
  { input }: CustomValidationProps,
  loadSuburbs: (city: string, methods: UseFormReturn) => void
) => {
  input.handleSuperChange = loadSuburbs;
};

const countryOfRegister = async ({ input }: CustomValidationProps, errorMessage: string) => {
  input.validations = {
    ...input.validations,
    validate: (value: string) => value !== 'México' ? errorMessage : true
  };
};

const rfc = async ({ input }: CustomValidationProps) => {
  input.validations = {
    ...input.validations,
    validate: (value: string) => {
      const rfcRegex = new RegExp(/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/);
      return rfcRegex.test(value.replaceAll(' ', '')) || 'El formato del RFC es inválido.'
    }
  };
};

const validations = {
  birthday,
  federalEntityOfBirth,
  phoneNumber,
  clabe,
  provinces,
  cities,
  countryOfRegister,
  formationDate,
  rfc
};

export default validations;

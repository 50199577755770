import { Option } from './Controls/Select/types';
import { ControlElement, FormProps } from './types';

export const normalizeRegexValidation = (pattern: any): void => {
  pattern.value = new RegExp(pattern.value);
};

export const loadOptions = (
  steps: FormProps[],
  fieldName: string,
  entries: Option[]
) => {
  steps.some((step: FormProps) => {
    let found = false;

    if (step.inputs) {
      step.inputs.some((inputsL2: ControlElement[]) => {
        let input = inputsL2.find((input: ControlElement) => {
          return input.name === fieldName;
        });
        if (input) {
          input!.options = entries;
          found = true;
        }
        return found;
      });
    }

    return found;
  });
};

import { ERROR_CODES } from './errorsCodes';
import { Option } from '../../components/GenericForm/Controls/Select/types';
import { Activity } from './types';
import { loadOptions } from '../../components/GenericForm/utils';
import activities from './data/activities.json';
import { Mask } from 'react-text-mask';

export const getErrorMessageByType = (error: any) => {
  let message: string = '';
  message = ERROR_CODES[error.code];

  if (!message) {
    return 'Algo salió mal, por favor intente de nuevo.';
  }
  return message;
};

export const loadActivities = (data: any) => {
  let activitiesList: Option[] = [];

  activities.forEach((activity: Activity) => {
    activitiesList.push({
      label: activity.name,
      value: String(activity.MCC)
    });
  });

  loadOptions(data.steps, 'activity', activitiesList);
};

export const loadLocations = async (
  locationType: 'provinces' | 'cities' | 'suburbs',
  group?: string,
  useId = false
) => {
  let locationsList: Option[] = [];
  let groupPadded = group ? group.padStart(4, '0') : '';
  const locations = await import(
    `./data/locations/${locationType}/${locationType}${groupPadded}.json`
  );
  for (const [key, value] of Object.entries<string>(locations)) {
    if (!isNaN(Number(key))) {
      const splittedLocation = value.split('|');
      locationsList.push({
        label: splittedLocation[1],
        value: useId ? splittedLocation[0] : splittedLocation[1],
        id: splittedLocation[0]
      });
    }
  }
  return locationsList;
};

const dateMask: Mask = [
  /[0-3]/,
  /\d/,
  '/',
  /[0,1]/,
  /\d/,
  '/',
  /[1-2]/,
  /[0,9]/,
  /\d/,
  /\d/
];

const phoneNumberMask: Mask = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const clabeInterbancariaMask: Mask = [
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/
];

const rfcMask: Mask = [
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-1]/,
  /[0-9]/,
  /[0-3]/,
  /[0-9]/,
  ' ',
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
];

const zipCodeMask: Mask = [/\d/, /\d/, /\d/, /\d/, /\d/];

export const getMask: (inputName: string) => Mask = (inputName: string) => {
  switch (inputName) {
    case 'birthday':
    case 'formationDate':
      return dateMask;
    case 'phoneNumber':
      return phoneNumberMask;
    case 'clabe':
      return clabeInterbancariaMask;
    case 'zipCode':
      return zipCodeMask;
    case 'rfc':
      return rfcMask;
    default:
      return [''];
  }
};

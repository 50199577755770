import React from 'react'
import { IllustrationContainer, Title, Subtitle, Text } from '../styles'
import { InfoFormProps } from '../types'
import DOMPurify from 'dompurify';


const InfoLayout = ({ activeForm, illustrationPosition }: InfoFormProps) => {
  return (
    <>
      {activeForm.illustration && illustrationPosition === 'form' && (
        <IllustrationContainer>
          <img
            src={`images/illustrations/${activeForm.illustration}.svg`}
            alt="ilustración"
          />
        </IllustrationContainer>
      )}
      {activeForm.title && <Title>{activeForm.title}</Title>}
      {
        activeForm.subtitle && (
          <Subtitle dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(activeForm.subtitle)
          }}
          />
        )
      }
      {
        activeForm.text && (
          <Text dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(activeForm.text)
          }}
          />
        )
      }
    </>
  )
}

export default InfoLayout
import React from 'react';
import { BackwardButtonProps } from '../UI/Navbar/types';
import { LeftArrowContainer } from './styles';

export const BackwardButton: React.FC<BackwardButtonProps> = ({
  showBackButton = false,
  handleClick
}) => {
  return (
    <LeftArrowContainer>
      {showBackButton && (
        <img src={'images/arrow-left.svg'} alt="back" onClick={handleClick} />
      )}
    </LeftArrowContainer>
  );
};

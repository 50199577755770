import React from 'react';
import { omit, pick } from '@styled-system/props';
import { useFormContext } from 'react-hook-form';
import { ControlElement } from '../../types';
import { SelectProps } from './types';
import { StyledOutlinedSelect, StyledSelect, StyledSelectBase } from './styles';

const selectVariants = {
  standard: StyledSelect,
  outlined: StyledOutlinedSelect
};

const SelectElement = React.forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      id,
      label,
      rightAddon,
      form_variant = 'standard',
      options,
      name = '',
      onChange,
      onBlur,
      value,
      handleSuperChange,
      ...rest
    },
    ref
  ) => {
    const wrapperProps = pick(rest);
    const inputProps = omit(rest);

    const SelectComponent = selectVariants[form_variant];
    const methods = useFormContext();
    const {
      formState: { errors, touchedFields },
      setValue,
      watch
    } = methods;
    const currentValue = watch(name);

    const handleChange = (e: any) => {
      setValue(name, e.target.value);
      if (handleSuperChange) {
        const selectedOption = options.find((o) => o.value === e.target.value)
        handleSuperChange(selectedOption?.id || selectedOption?.value, methods);
      }
      onChange!(e);
    };

    const error: boolean = errors[name] && touchedFields[name];

    return (
      <SelectComponent {...wrapperProps} error={Boolean(error)}>
        <StyledSelectBase
          defaultValue=""
          name={name}
          ref={ref}
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          {...inputProps}
        >
          <option disabled></option>
          {options.map((option, index) => {
            return (
              <option value={option.value} key={`${name}-${index}`}>
                {option.label}
              </option>
            );
          })}
          {rightAddon}
        </StyledSelectBase>
        <label
          htmlFor={name}
          className={currentValue && currentValue !== '' ? 'has-value' : ''}
        >
          {label}
        </label>
        {error && (
          <span>
            <>{errors[name]!.message}</>
          </span>
        )}
      </SelectComponent>
    );
  }
);

export const Select: React.FC<ControlElement> = (
  { name, placeholder, options = [], validations, handleSuperChange },
  props: any
) => {
  const { register } = useFormContext();
  const sortedOptions = options.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  })
  return (
    <SelectElement
      {...register(name, validations)}
      label={placeholder}
      options={sortedOptions}
      handleSuperChange={handleSuperChange}
      {...props}
    />
  );
};

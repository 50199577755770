// export const country = process.env.REACT_APP_ONBOARDING_COUNTRY!;
// export const registerProduct = process.env.REACT_APP_REGISTER_PRODUCT!;

export const endpoints = {
  VERIFY_CODE: '/1/auth/send-mail',
  RESEND_CODE: '/1/auth/resend-code',
  SIGNUP_USER_FORM: '/account',
  ONBOARDING_FORM: '/onboarding/{email}',
  ONBOARDING_FORM_PPMM: '/organizations/onboarding',
  REGISTRATION_STATUS: '/accounts/{email}',
  SEND_EMAIL: '/auth0/send-email',
  SEND_EMAIL_CODE: '/auth0/code'
};

export const REGISTRATION_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  INCOMPLETE: 'INCOMPLETE',
  FINISHED: 'FINISHED'
};

export const PERSON_TYPE = {
  PPFF: 'OCCASIONAL',
  PPMM: 'COMPANY',
  PROFESSIONAL: 'PROFESSIONAL'
};

import React from 'react'
import { CustomButtonsContainer } from './styles'
import { FormVariants } from '../../types';

const ButtonsContainer = ({ children, form_variant }: {
  children?: JSX.Element | JSX.Element[];
  form_variant?: FormVariants;
}) => {
  return (
    <CustomButtonsContainer className={form_variant}>
      {children}
    </CustomButtonsContainer>
  )
}

export default ButtonsContainer
import React from 'react';
import useDesktopSize from '../../../hooks/useDesktopSize';
import Navbar from '../../UI/Navbar';
import {
  Wrapper,
  FormContainer,
  FormSubContainer,
  SideIllustrationContainer
} from './styles';
import { FormLayoutProps } from './types';

export const FormLayout: React.FC<FormLayoutProps> = ({
  children,
  showLogo = false,
  showBackButton = false,
  activeStep = undefined,
  maxFormSteps = 0,
  handleBack = () => { },
  illustration = undefined,
  form_variant = undefined
}) => {
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  return (
    <Wrapper hasIllustration={!!illustration}>
      <FormContainer className={form_variant}>
        <FormSubContainer className={form_variant}>
          <Navbar
            showBackButton={showBackButton}
            showLogo={showLogo}
            handleBack={handleBack}
            activeStep={activeStep}
            maxFormSteps={maxFormSteps}
            form_variant={form_variant}
          />
          {children}
        </FormSubContainer>
      </FormContainer>
      {isDesktop && illustration && (
        <SideIllustrationContainer>
          <img
            src={`images/illustrations/${illustration}.svg`}
            alt="ilustración"
          />
        </SideIllustrationContainer>
      )}
    </Wrapper>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 0 auto;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-30px);

  & div[role='button'] {
    width: auto;
  }

  @media (max-width: 300px) {
    * {
      font-size: 0.7rem;
    }
    & div.small-phone-uala {
      width: 76px;
      height: 76px;
    }
  }
`;

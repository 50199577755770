import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContextDispatch, UserDataContext } from '../../contexts/UserDataProvider';
import {
  VerificationFormContainer,
  VerificationSubtitle,
  VerificationTitle,
  ResendButton,
  StyledPinInput,
  VerificationContainer,
  WrongEmail,
  Timer,
  Error,
  CustomIllustrationContainer
} from './styles';
import { useSnackbar } from '@uala/ui';
import { useGetDevice } from '../../hooks/useDesktopSize';
import { createAmplitudeEvent, setAmplitudUserId } from '../../contexts/AmplitudeProvider';
import LoadingScreen from '../../components/UI/LoadingScreen';
import FormLayout from '../../components/GenericForm/FormLayout';
import moment, { Duration } from 'moment';
import { Link } from 'react-router-dom';
import useDesktopSize from '../../hooks/useDesktopSize';
import { sendEmailValidation, validateEmailOtp } from '../../services/auth';
import { AxiosError } from 'axios';
import { PERSON_TYPE } from '../../shared/constants';

export const ValidateEmail: React.FC = () => {
  const CODE_LENGTH: number = 6;
  const TIMER_DURATION: number = 180; // in seconds
  const INTERVAL: number = 1;
  const user = useContext(UserDataContext);
  const setUser = useContext(UserContextDispatch);
  const navigate = useNavigate();
  const addSnackbar = useSnackbar();
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  const device = useGetDevice();
  const source = getSource();
  const personType = window.sessionStorage.getItem('personType') || PERSON_TYPE.PPFF

  const [shouldWaitResend, setShouldWaitResend] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState<Duration>(
    moment.duration(TIMER_DURATION, 'seconds')
  );
  const [value, setValue] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  useEffect(() => {
    if (user && user.accountEmail) {
      sendEmailValidation(user.accountEmail)
        .catch(() => {
          createAmplitudeEvent('registro_cobros_error_api', { device, tipo_error: "enviar_token", user_type: personType })
          addSnackbar('Error al enviar mail');
        });
    }
  }, [user])

  function getSource(): ("crea_cuenta" | "retoma_onboarding" | "unknown") {
    const source = new URLSearchParams(document.location.search).get("source");
    if (source === "ca") {
      return "crea_cuenta";
    }
    if (source === "ro") {
      return "retoma_onboarding";
    }
    return "unknown";
  }

  useEffect(() => {
    if (timer.asSeconds() < 1) {
      setShouldWaitResend(false);
      return;
    }

    const intervalId = setInterval(function () {
      setTimer(moment.duration(timer.asSeconds() - INTERVAL, 'seconds'));
    }, INTERVAL * 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  const handleChange = async (code: string) => {
    setValue(code);
    if (code.length === CODE_LENGTH) {
      try {
        setIsLoading(true);
        const { data } = await validateEmailOtp(code, user?.accountEmail as string)
        setUser((user) => ({ ...user, "accessToken": data.AccessToken, userId: data.UserId }))
        setAmplitudUserId(data.UserId)
        createAmplitudeEvent('registro_cobros_valida_mail', { device, source, success: true, user_type: personType })
        navigate('/data-request')
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 400) {
            createAmplitudeEvent('registro_cobros_valida_mail', { device, source, success: false, user_type: personType })
            setIsInvalid(true);
            return
          }
        }
        createAmplitudeEvent('registro_cobros_error_api', { device, tipo_error: "validar_token", user_type: personType })
        addSnackbar('Error al validar el código');
      }
      finally {
        setValue('');
        setIsLoading(false);
      }
    } else {
      setIsInvalid(false);
    }
  };

  const handleResend = async () => {
    try {
      if (user && user.accountEmail) {
        setIsLoading(true);
        createAmplitudeEvent('registro_cobros_selecciona_reenviar_código', { device, source, user_type: personType });
        await sendEmailValidation(user.accountEmail)
        setValue('');
        setTimer(moment.duration(TIMER_DURATION, 'seconds'));
        setIsLoading(false);
        setShouldWaitResend(true);
      }
    } catch (error) {
      createAmplitudeEvent('registro_cobros_error_api', { device, tipo_error: "enviar_token", user_type: personType })
      setIsLoading(false);
      addSnackbar('Error al enviar mail');
    }
  };

  const isExpired = timer.asSeconds() < 1;

  return (
    <FormLayout
      showLogo={isDesktop}
      illustration={isDesktop ? 'email-validation' : undefined}
    >
      {!isDesktop && (
        <CustomIllustrationContainer>
          <img
            src={`images/illustrations/email-validation.svg`}
            alt="ilustración"
          />
        </CustomIllustrationContainer>
      )}
      <VerificationContainer>
        <VerificationFormContainer method="POST">
          <VerificationTitle>{source === 'retoma_onboarding' ? 'Retomar Registro' : 'Valida tu mail'}</VerificationTitle>
          <VerificationSubtitle>
            Ingresa el código que te enviamos a <b>{user?.accountEmail}</b>
          </VerificationSubtitle>
          <StyledPinInput
            id="pin-input"
            length={CODE_LENGTH}
            type="number"
            value={value}
            onChange={handleChange}
            disabled={isLoading || isExpired}
            autoFocus
            required
            className={isExpired || isInvalid ? 'expired' : 'undefined'}
          />
        </VerificationFormContainer >
        {isExpired && <Error>Este código expiró</Error>}
        {
          isInvalid && (
            <Error>Tu código es incorrecto, vuelve a escribirlo</Error>
          )
        }
        <Timer error={isExpired}>
          {timer.minutes() + ':' + timer.seconds().toString().padStart(2, '0')}
        </Timer>
        <ResendButton
          type="button"
          onClick={handleResend}
          disabled={shouldWaitResend || isLoading}
          isDisabled={shouldWaitResend}
        >
          {!shouldWaitResend && 'Reenviar código'}
        </ResendButton>
        <WrongEmail>
          Este no es mi mail,{' '}
          <Link onClick={() => {
            createAmplitudeEvent("registro_cobros_cambia_mail", { device, user_type: personType })
          }} to={'/create-account'}>quiero cambiarlo.</Link>
        </WrongEmail>
        {isLoading && <LoadingScreen />}
      </VerificationContainer >
    </FormLayout >
  );
};

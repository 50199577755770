import { useState, useEffect, createContext } from 'react';
import amplitude from 'amplitude-js';
import {
  getOptionsByPathname,
  getOptionsByUrlSearch
} from '../utils/amplitude';

export const createAmplitudeEvent = (
  name: string,
  params: object = {},
  callback: amplitude.Callback = () => { },
  formData?: object
): void => {
  const options = {
    ...params,
    country: process.env.REACT_APP_ONBOARDING_COUNTRY,
    ...getOptionsByPathname(formData),

    ...getOptionsByUrlSearch(name)
  };
  amplitude.getInstance().logEvent(name, options, callback);
};

export const setAmplitudUserId = (userId: string) => {
  amplitude.getInstance().setUserId(userId);
}

export const setUserProp = (key: string, value: string | number) => {
  const identify = new amplitude.Identify().set(key, value);
  amplitude.getInstance().identify(identify);
}

export const AmplitudeContext = createContext({ isInitiated: false });

const AmplitudeProvider: React.FC<{ children: JSX.Element }> = ({
  children
}) => {
  const [isInitiated, setIsinitiated] = useState(false);

  useEffect(() => {
    if (!isInitiated) {
      amplitude
        .getInstance()
        .init(
          process.env.REACT_APP_PUBLIC_AMPLITUDE_API_KEY ?? '',
          undefined,
          undefined,
          () => {
            setIsinitiated(true);
          }
        );
      const identify = new amplitude.Identify().set('country', 'MEX');
      amplitude.getInstance().identify(identify);
    }
  });

  return (
    <AmplitudeContext.Provider value={{ isInitiated }}>
      {children}
    </AmplitudeContext.Provider>
  );
};

export default AmplitudeProvider;

import React from 'react';
import {
  CardHorizontal as Card,
  Button,
  Text,
  Stack,
  Spacer
} from '@uala/abra';
import amplitude from 'amplitude-js';
import GenericForm from '../../components/GenericForm';
import dataPPFF from './data/pendingApprovalFormPPFF.json';
import dataPPMM from './data/pendingApprovalFormPPMM.json';
import { getEventNameByPathname } from '../../utils/amplitude';
import useAmplitudeEvent from '../../hooks/useAmplitudEvente';
import useDesktopSize, { useGetDevice } from '../../hooks/useDesktopSize';
import { PERSON_TYPE } from '../../shared/constants';
import useGtmEvent from '../../hooks/useGtmEvent';
import {
  createAmplitudeEvent,
  setUserProp
} from '../../contexts/AmplitudeProvider';
import { CardContainer, Container, Image } from './styles';

const PendingApproval: React.FC = () => {
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  const personType =
    window.sessionStorage.getItem('personType') || PERSON_TYPE.PPFF;
  const currentData = personType === PERSON_TYPE.PPMM ? dataPPMM : dataPPFF;
  setUserProp('user_type', personType);
  useAmplitudeEvent(getEventNameByPathname(), { user_type: personType }, () => {
    amplitude.getInstance().resetSessionId();
  });

  useGtmEvent('Pantalla TYP');
  const device = useGetDevice();

  return (
    <Container>
      <GenericForm
        form={currentData}
        loading={false}
        showLogo={isDesktop}
        illustrationPosition="form"
        form_variant="feedback-page"
      />
      <CardContainer>
        {device === 'desktop' ? (
          <Stack spacing={40} direction="column" align="center">
            <Text size="sm">Escanea el QR con la cámara de tu celular:</Text>

            <Image src="images/qr.png" alt="QR" width="185px" height="185px" />

            <Button
              href="https://buy-reader.prod.adquirencia.mx.ua.la/buy/?token=pt_3861d0cc41d9dc9e5b4a396d6ae1c9202d0ba3dcc80917ed69228b915bc8554d"
              target="_blank"
              as="a"
              onClick={() => {
                createAmplitudeEvent('cobros_compra_mPOS', {
                  device,
                  source: 'onboarding_mex'
                });
              }}
            >
              Comprar lector de tarjeta
            </Button>
          </Stack>
        ) : (
          <Stack direction="column">
            <Spacer y={20} />

            <Card
              title="Comprar lector"
              description="Acepta tarjetas de crédito y débito, chip y contactless."
              illustration={'small-phone-uala'}
              css={{ mx: '$24', minWidth: 'unset', maxWidth: '350px' }}
              onClick={() => {
                createAmplitudeEvent('cobros_compra_mPOS', {
                  device,
                  source: 'onboarding_mex'
                });
                window.location.href =
                  'https://buy-reader.prod.adquirencia.mx.ua.la/buy/?token=pt_3861d0cc41d9dc9e5b4a396d6ae1c9202d0ba3dcc80917ed69228b915bc8554d';
              }}
            ></Card>

            <Spacer y={40} />

            <Stack justify="center">
              <Button
                href="https://ualabismx.onelink.me/C4jd/m8jyupzg"
                target="_blank"
                as="a"
                onClick={() => {
                  createAmplitudeEvent('registro_cobros_finaliza_registro', {
                    device,
                    source: 'onboarding_mex'
                  });
                }}
              >
                Empezar a cobrar
              </Button>
            </Stack>
          </Stack>
        )}
      </CardContainer>
    </Container>
  );
};

export default PendingApproval;

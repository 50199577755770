import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  grid-template-columns: 50px 70%;
  flex-direction: row;
  align-items: center;
  margin: 0;

  & > span {
    min-width: 1.5rem;
    margin: 0px 16px 0px 25px;
    @media (max-width: 1200px) {
      margin-left: 0;
    }
  }
`;

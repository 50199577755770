import React from 'react';
import { CardHorizontal as Card } from '@uala/abra';
import amplitude from 'amplitude-js';
import GenericForm from '../../components/GenericForm';
import data from './data/FinishedOnboardingPendingValidate.json';
import { getEventNameByPathname } from '../../utils/amplitude';
import useAmplitudeEvent from '../../hooks/useAmplitudEvente';
import useDesktopSize, { useGetDevice } from '../../hooks/useDesktopSize';
import { createAmplitudeEvent } from '../../contexts/AmplitudeProvider';
import { PERSON_TYPE } from '../../shared/constants';
import { CardContainer, Container } from './styles';

const FinishedOnboardingPendingValidate: React.FC = () => {
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  const personType = window.sessionStorage.getItem('personType') || PERSON_TYPE.PPFF;
  useAmplitudeEvent(getEventNameByPathname(), { user_type: personType }, () => {
    amplitude.getInstance().resetSessionId();
  });
  const device = useGetDevice();

  return (
    <Container>
      <GenericForm
        form={data}
        showBackButton
        firstBackButton={!isDesktop ? '/create-account' : undefined}
        loading={false}
        showLogo={isDesktop}
        illustrationPosition={'form'}
        form_variant={'feedback-page'}
      />
      <CardContainer>
        <Card
          title="Comprar lector"
          description="Acepta tarjetas de crédito y débito, chip y contactless."
          illustration={'small-phone-uala'}
          css={{ mx: '$24', minWidth: 'unset', maxWidth: '350px' }}
          onClick={() => {
            createAmplitudeEvent('cobros_compra_mPOS', {
              device,
              source: 'onboarding_mex'
            });
            window.location.href =
              'https://buy-reader.prod.adquirencia.mx.ua.la/buy/?token=pt_3861d0cc41d9dc9e5b4a396d6ae1c9202d0ba3dcc80917ed69228b915bc8554d';
          }}
        ></Card>
      </CardContainer>
    </Container>
  );
};

export default FinishedOnboardingPendingValidate;

import { useEffect, useState } from 'react';
import useWindowSize from './useWindowSize';

declare global {
  interface Window { dataLayer: any[]; }
}

const useGtmEvent = (eventName: string) => {
  const { width } = useWindowSize();
  const [eventSend, setEventSend] = useState(false);
  useEffect(() => {
    if (width > 0 && !eventSend) {
      window.dataLayer.push({
        event: 'trackEvent',
        eventCategory: 'Onboarding',
        eventAction: 'View',
        eventLabel: eventName
      });

      setEventSend(true);
    }
  }, [width, eventSend, eventName]);
  return eventSend;
};

export default useGtmEvent;
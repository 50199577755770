import React from 'react';
import { SnackbarProvider, ThemeProvider } from '@uala/ui';
import { QueryClientProvider } from 'react-query';
import queryClient from './config/queryClient';
import { GlobalStyles } from './utils/globalStyles';
import UserDataProvider from './contexts/UserDataProvider';
import Routes from './Routes';
import { Container } from './styles';
import AmplitudeProvider from './contexts/AmplitudeProvider';
import { AbraProvider } from '@uala/abra'

const App: React.FC = () => {
  // Google Tag Manager
  (function (i: string) {
    const w = window
    const d = document;
    const l = 'dataLayer'
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName('script')[0],
      j = d.createElement('script'),
      dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    if (f.parentNode) {
      f.parentNode.insertBefore(j, f);
    }
  })(`GTM-${process.env.REACT_APP_PUBLIC_GTM_ID}`);

  return (
    <ThemeProvider>
      <AbraProvider>
        <SnackbarProvider>
          <AmplitudeProvider>
            <UserDataProvider>
              <QueryClientProvider client={queryClient}>
                <GlobalStyles />
                <Container>
                  <Routes />
                </Container>
              </QueryClientProvider>
            </UserDataProvider>
          </AmplitudeProvider>
        </SnackbarProvider>
      </AbraProvider>
    </ThemeProvider>
  );
};

export default App;

import styled from 'styled-components';

export const LoadingScreenBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  svg {
    circle.path {
      stroke: #3564fd;
    }
  }
  @media (max-width: 1200px) {
    height: 100%;
    width: 100vw;
    background-color: #3a3a3a;
    opacity: 0.5;
    top: 0px;
    right: 0px;
    position: fixed;
    z-index: 100;

    circle.path {
      circle {
        stroke: #ffffff;
      }
    }
  }
`;

import React from 'react';
import { Stack, Title, CardHorizontal } from '@uala/abra';
import GenericForm from '../../components/GenericForm';
import useDesktopSize, { useGetDevice } from '../../hooks/useDesktopSize';
import { useNavigate } from 'react-router-dom';
import { PERSON_TYPE } from '../../shared/constants';
import useAmplitudeEvent from '../../hooks/useAmplitudEvente';
import { getEventNameByPathname } from '../../utils/amplitude';
import { createAmplitudeEvent } from '../../contexts/AmplitudeProvider';
import useGtmEvent from '../../hooks/useGtmEvent';

const SelectPersonType = () => {
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  const navigate = useNavigate();
  useAmplitudeEvent(getEventNameByPathname());
  useGtmEvent('Pantalla Inicial');
  const device = useGetDevice();

  const setPersonType = (personType: string) => {
    window.sessionStorage.setItem('personType', personType);
    navigate('/create-account');
    createAmplitudeEvent('registro_cobros_selecciona_persona', {
      user_type: personType,
      device
    });
  };

  return (
    <GenericForm
      form={{
        id: '1',
        name: 'person-type',
        illustration: 'create-account',
        redirect_button: {
          label: '¿Cómo saber si soy persona moral?',
          route: '/about-ppmm'
        }
      }}
      loading={false}
      showLogo={isDesktop}
      illustrationPosition={isDesktop ? 'side' : undefined}
      form_variant="feedback-page"
    >
      <Stack direction="column" align="center">
        <Title css={{ padding: '$24' }}>¿Cómo quieres registrarte?</Title>

        <Stack direction="column" css={{ maxWidth: '320px' }} spacing="16">
          <CardHorizontal
            title="Persona física (PF)"
            description="Comerciante."
            icon="stores"
            onClick={() => setPersonType(PERSON_TYPE.PPFF)}
          />

          <CardHorizontal
            title="PF con actividad empresarial"
            description="Prefesional independiente."
            icon="products"
            onClick={() => setPersonType(PERSON_TYPE.PROFESSIONAL)}
          />

          <CardHorizontal
            title="Persona moral"
            description="Empresa registrada en el SAT"
            icon="clients"
            onClick={() => setPersonType(PERSON_TYPE.PPMM)}
          />
        </Stack>
      </Stack>
    </GenericForm>
  );
};

export default SelectPersonType;

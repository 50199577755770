import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  display: flex;

  @media (max-width: 1200px) {
    overflow: scroll;
  }

  @media (max-width: 480px) {
    box-sizing: border-box;
    padding: 0;
  }
`;

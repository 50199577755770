import React, { useEffect, useState } from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFormContext
} from 'react-hook-form';
import { ControlElement } from '../../types';
import {
  Container,
  CustomMaskedInput,
  HelperText,
  Label,
  Wrapper
} from './styles';

export const InputMask: React.FC<ControlElement> = ({
  name,
  placeholder,
  description = '',
  maskExp,
  validations
}) => {
  const {
    getValues,
    setValue,
    register,
    watch,
    formState: { errors, touchedFields }
  } = useFormContext();
  const [isInFocus, setIsInFocus] = useState(false);
  const inputField = register(name, validations);
  const [helperText, setHelperText] = useState<string>('');
  const currentValue = watch(name);

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    inputField.onBlur(e);
    setIsInFocus(false);
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsInFocus(true);
  };

  const error = errors[name] && touchedFields[name];

  useEffect(() => {
    let text:
      | string
      | FieldError
      | Merge<FieldError, FieldErrorsImpl<any>>
      | undefined = '';

    text = description;

    if (error && !isInFocus) {
      text = errors[name]!.message;
    }

    if (typeof text === 'string') {
      setHelperText(text);
    }
  }, [description, error, errors, getValues, isInFocus, name]);

  const handleChange = (e: any) => {
    setValue(name, e.currentTarget.value, { shouldValidate: true });
    inputField.onChange(e);
  };

  return (
    <Wrapper error={error && !isInFocus} focused={isInFocus}>
      <Container>
        <CustomMaskedInput
          {...inputField}
          id={name}
          name={name}
          mask={maskExp!}
          placeholderChar={'_'}
          guide={false}
          onChange={handleChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          value={getValues(name)}
        ></CustomMaskedInput>
        {placeholder && (
          <Label
            htmlFor={name}
            className={currentValue && currentValue !== '' ? 'has-value' : ''}
          >
            {placeholder}
          </Label>
        )}
      </Container>
      {<HelperText>{helperText}</HelperText>}
    </Wrapper>
  );
};

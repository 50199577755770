import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom'

export const LinkContainer = styled.div`
  text-align: center;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const Link = styled(RouterLink)`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
`;

import { Cordinates } from '../components/GenericForm/types';
import { createAmplitudeEvent } from '../contexts/AmplitudeProvider';

export const handleGeolocalization = (
  happyPath: boolean,
  device: 'desktop' | 'mobile',
  personType: string
): Promise<Cordinates> => {
  const geolocationPath = happyPath ? 'happy_path' : 'manual';

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const cordinates = {
          latitude: position.coords.latitude.toString(),
          longitude: position.coords.longitude.toString()
        };

        createAmplitudeEvent('registro_cobros_permite_acceso_geolocalizacion', {
          device,
          user_type: personType,
          source: geolocationPath
        });

        resolve(cordinates);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

import { useContext, useEffect, useState } from 'react';
import {
  AmplitudeContext,
  createAmplitudeEvent
} from '../contexts/AmplitudeProvider';
import { useGetDevice } from './useDesktopSize';
import useWindowSize from './useWindowSize';

const useAmplitudeEvent = (eventName: string, customParams = {}, callback = () => { }) => {
  const { width } = useWindowSize();
  const AmplitudData = useContext(AmplitudeContext);
  const [eventSend, setEventSend] = useState(false);
  const device = useGetDevice();
  useEffect(() => {
    if (AmplitudData.isInitiated && width > 0 && !eventSend) {
      createAmplitudeEvent(eventName, { device, ...customParams }, callback);
      setEventSend(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AmplitudData.isInitiated, width]);
  return eventSend;
};

export default useAmplitudeEvent;

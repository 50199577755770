import React from 'react'
import { useNavigate } from 'react-router-dom';
import GenericForm from '../../components/GenericForm';
import data from './data/aboutPpmmForm.json'
import useDesktopSize from '../../hooks/useDesktopSize';
import useAmplitudeEvent from '../../hooks/useAmplitudEvente';
import { getEventNameByPathname } from '../../utils/amplitude';

const AboutPpmm = () => {
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  const navigate = useNavigate()
  useAmplitudeEvent(getEventNameByPathname())
  return (
    <GenericForm
      form={data}
      firstBackButton={!isDesktop ? '/?isFyf=true' : undefined}
      showBackButton
      onSubmit={() => navigate('/?isFyf=true')}
      loading={false}
      showLogo={isDesktop}
      illustrationPosition={isDesktop ? 'side' : undefined}
      form_variant='feedback-page'
    />
  );
}

export default AboutPpmm
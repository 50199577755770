import React from 'react';
import BackwardButton from '../../BackwardButton';
import { Container, SubContainer, StepperHelper, UalaBisLogo } from './styles';
import { NavbarProps } from './types';

export const Navbar: React.FC<NavbarProps> = ({
  showLogo = false,
  showBackButton = false,
  activeStep = undefined,
  maxFormSteps = 0,
  form_variant = undefined,
  handleBack = () => { }
}) => {
  return (
    <>
      {(showBackButton || showLogo || maxFormSteps > 0) && (
        <Container className={form_variant}>
          {
            (form_variant !== 'simple' || showBackButton) && (
              <BackwardButton
                showBackButton={showBackButton}
                handleClick={handleBack}
              />
            )
          }
          <SubContainer className={form_variant}>
            {showLogo && <UalaBisLogo src={'images/logo-uala-bis.svg'} />}
            {activeStep && maxFormSteps > 1 && (
              <StepperHelper>
                <span>
                  Paso {activeStep} de {maxFormSteps}
                </span>
              </StepperHelper>
            )}
          </SubContainer>
        </Container>
      )}
    </>
  );
};

import { endpoints } from '../../shared/constants';
import { authInstance, onboardingInstance } from '../../shared/axios';

const validateUserSignupCode = async (code: string, email: string) => {
  const body = {
    pin: code,
    email
  };
  const response = await authInstance.post(endpoints.VERIFY_CODE, body);
  return response.data;
};

const resendVerificationCodeByEmail = async (email: string) => {
  const body = {
    email
  };
  return await authInstance.post(endpoints.RESEND_CODE, body);
};

const sendEmailValidation = async (email: string) => {
  await onboardingInstance.post(endpoints.SEND_EMAIL, { Email: email })
}

const validateEmailOtp = (code: string, email: string) => {
  return onboardingInstance.post<{ AccessToken: string, UserId: string }>(endpoints.SEND_EMAIL_CODE, { Code: code, Username: email })
}

export { validateUserSignupCode, resendVerificationCodeByEmail, sendEmailValidation, validateEmailOtp };

